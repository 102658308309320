const { when } = require('jquery');

$(document).on('turbolinks:load', () => {

  if($('.step9-distribution').length) {
    function setValueInputs(element) {
      let columns = $(element).parent().parent().parent().find('.column-marketing-stage');
      columns.each(function() {
        let inputs_by_col = $(this).find('input');
        inputs_by_col.each(function(index) {
          if(index != 0) {
            if($(inputs_by_col[index]).hasClass('percentage-input')) {
              let before_value = inputs_by_col[index-1];
              let current_value = inputs_by_col[index];
              let next_value = inputs_by_col[index+1];
    
              let new_value = (parseFloat(before_value.value) * ((parseFloat(current_value.value) / 100) + 1));
              next_value.value = new_value

              new_value = parseFloat(new_value, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
              $(next_value).parent().find('.value-input').text(new_value);
            }
          }
        })
      });
    }

    $('input').change(function() {
      let columns = $(this).parent().parent().parent().find('.column-marketing-stage');
      columns.each(function() {
        let inputs_by_col = $(this).find('input');
        inputs_by_col.each(function(index) {
          if(index != 0) {
            if($(inputs_by_col[index]).hasClass('percentage-input')) {
              let before_value = inputs_by_col[index-1];
              let current_value = inputs_by_col[index];
              let next_value = inputs_by_col[index+1];

              let current_value_val = 0.0;
              if(current_value.value == '') {
                current_value_val = 0.0;
              }else{
                current_value_val = parseFloat(current_value.value);
              }

              next_value.value = (parseFloat(before_value.value) * ((current_value_val / 100) + 1)).toFixed(2);
              let value_show = parseFloat(next_value.value, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
              $(next_value).parent().find('span').text(value_show);
            }
          }
        })
      });
    });

    $('.form-radio').change(function() {
      let params = $('.select-model').val();
      if(params == null) {
        return;
      }
      let ids = params.split(';');
      
      changeInputs(ids);
    })

    $('.select-model').change(function() {
      let params = $(this).val();
      let ids = params.split(';');
      
      changeInputs(ids);
    })

    function changeInputs(ids) {
      let ip_unit_type_id = ids[0];
      let unit_name_id = ids[1];
      let unit_model_id = ids[2];
      
      let income_planning_id = $('#income_planning_id').val();

      $.ajax({
        url: `/unit_models/${unit_model_id}`,
        method: "GET",  
        dataType: "json",
        data: {
          unit_model: {
            ip_unit_type: ip_unit_type_id,
            unit_name: unit_name_id
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          let radio = '';
          if ($('#radio-price-total').is(':checked')) {
            radio = 'price-total';
          }

          if($('#radio-price-m2').is(':checked')) {
            radio = 'price-m2';
          }

          let ip_marketing_stage_ids = Object.keys(response['price_by_ip_marketing_stage']);
          ip_marketing_stage_ids.forEach(function(currentValue) {
            if(radio == 'price-total') {
              $(`.${currentValue}`).val(response['price_by_ip_marketing_stage'][currentValue]['price_total']);
            }
            if(radio == 'price-m2') {
              $(`.${currentValue}`).val(response['price_by_ip_marketing_stage'][currentValue]['price_m2']);
            }
          })
          setValueInputs($('.select-model'));
          $('.total_m2').val(response['m2_total']);
          $('.total_m2_sold').val(response['m2_considerated']);
        }
      });
    }
  }
  
  $('.save-distribution-form').click(function () {
    let income_planning_id = $('#percentage_overall_increase_income_planning').val();
    let form =  $(this).parent().parent();

    let marketing_stages_ids = {};
    let marketing_stages = form.find('.ip_marketing_stage_id').each(function() {
      marketing_stage = $(this).val();
      marketing_stages_ids[marketing_stage] = [];
      let container = $(this).parent().find('.container-financing').find('button').each(function() {
        marketing_stages_ids[marketing_stage].push($(this).find('.ip_finacing_id').val());
      });
    });

    $.ajax({
      url: "/percentage_overall_increases",
      method: "POST",  
      dataType: "json",
      data: {
        percentage_overall_increase: {
          income_planning: income_planning_id,
          marketing_stages_ids: marketing_stages_ids,
        }
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        window.location= window.location.href.replace('step8', 'step9');
      }
    });
  });

  $('.save-step9').click(function () {
    let income_planning_id = $('#percentage_overall_increase_income_planning').val();
    let data = {};

    $('.column-marketing-stage').each(function() {
      let marketing_stage_id = '';

      $(this).find('input').each(function() {
        if($(this).hasClass('price_by_marketing_stage')) {
          marketing_stage_id = $(this).attr('class').split(' ')[1];
          data[$(this).attr('class').split(' ')[1]] = [];
        }
        if($(this).hasClass('percentage-input')) {
          data[marketing_stage_id].push($(this).val());
        }
      });
    });

    $.ajax({
      url: "/percentage_overall_increases/add_percentages",
      method: "POST",  
      dataType: "json",
      data: {
        percentage_overall_increase: {
          income_planning: income_planning_id,
          data: data,
        }
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        if (response['success'] == true) {
          window.location= window.location.href.replace('step9', 'step10');
        }else{
          Swal.fire(
            'Alerta',
            'Hubo un error, intenta más tarde.',
            'warning'
          )
        }
      }
    });
  });

});