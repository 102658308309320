const moment = require('moment');

$(document).on('turbolinks:load', () => {
  let no_weeks = moment(new Date(new Date().getFullYear(),11,28)).isoWeek();
  let weeks = createArrayWeek(no_weeks);
  let months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
  let type = $('#type-balance');
  let budget = $('#actual-balance');
  let span_text = $('#date-span');

  $('#type-balance').click(() => {
    type_budget = type.val();
    if(type_budget == 0) {
      let actual_year = budget.text().substr(-4);
      budget.text(actual_year);
      span_text.text('');
    }
    if(type_budget == 1) {
      let actual_year = budget.text().substr(-4);
      budget.text(months[0] + ' ' + actual_year);
      span_text.text('');
    }
    if(type_budget == 2) {
      let actual_year = budget.text().substr(-4);
      budget.text(weeks[0] + ' ' + actual_year);
      let weekStart = moment(new Date(actual_year,0,4)).clone().startOf('isoweek').format('DD MM YYYY');
      let weekEnd = moment(new Date(actual_year,0,4)).clone().endOf('isoweek').format('DD MM YYYY');
      span_text.text("Semana 1 va del " + weekStart + " al " + weekEnd);
    }
  });

  $('#before-balance').click(() => {
    type_budget = type.val();
    if(type_budget == 0) {
      let actual_year = parseInt(budget.text());
      actual_year--;
      budget.text(actual_year);
    }
    if(type_budget == 1) {
      let actual_year = parseInt(budget.text().substr(-4));
      let actual_month = budget.text().replace(` ${actual_year}`, '');
      let index_month = parseInt(months.indexOf(actual_month));

      if(index_month == 0) {
        actual_year--
        budget.text(months[11] + ' ' + actual_year);
      }else {
        index_month--
        budget.text(months[index_month] + ' ' + actual_year);
      }
    }
    if(type_budget == 2) {
      let actual_year = parseInt(budget.text().substr(-4));
      let actual_week = budget.text().replace(` ${actual_year}`, '');
      let index_week = parseInt(weeks.indexOf(actual_week));
      let weekStart = '';
      let weekEnd = '';
      
      if(index_week == 0) {
        actual_year--
        let no_weeks = moment(new Date(actual_year,11,28)).isoWeek();
        weeks = createArrayWeek(no_weeks);
        index_week = parseInt(weeks.indexOf(actual_week));
        let last_index_week = weeks.length - 1
        weekStart = moment(new Date(actual_year,0,4)).add((last_index_week), 'weeks').clone().startOf('isoweek').format('DD MM YYYY');
        weekEnd = moment(new Date(actual_year,0,4)).add((last_index_week), 'weeks').clone().endOf('isoweek').format('DD MM YYYY');
        budget.text(weeks[last_index_week] + ' ' + actual_year);
      }else {
        index_week--
        weekStart = moment(new Date(actual_year,0,4)).add((index_week), 'weeks').clone().startOf('isoweek').format('DD MM YYYY');
        weekEnd = moment(new Date(actual_year,0,4)).add((index_week), 'weeks').clone().endOf('isoweek').format('DD MM YYYY');
        budget.text(weeks[index_week] + ' ' + actual_year);
      }
      span_text.text("Semana " + (index_week + 1) + " va del " + weekStart + " al " + weekEnd);
    }
  });

  $('#next-balance').click(() => {
    type_budget = type.val();
    if(type_budget == 0) {
      let actual_year = parseInt(budget.text());
      actual_year++;
      budget.text(actual_year);
    }
    if(type_budget == 1) {
      let actual_year = parseInt(budget.text().substr(-4));
      let actual_month = budget.text().replace(` ${actual_year}`, '');
      let index_month = parseInt(months.indexOf(actual_month));

      if(index_month == 11) {
        actual_year++
        budget.text(months[0] + ' ' + actual_year);
      }else {
        index_month++;
        budget.text(months[index_month] + ' ' + actual_year);
      }
    }
    if(type_budget == 2) {
      let actual_year = parseInt(budget.text().substr(-4));
      let no_weeks = moment(new Date(actual_year,11,28)).isoWeek();
      weeks = createArrayWeek(no_weeks);
      let actual_week = budget.text().replace(` ${actual_year}`, '');
      let index_week = parseInt(weeks.indexOf(actual_week));
      let last_index_week = weeks.length - 1
      let weekStart = '';
      let weekEnd = '';

      if(index_week == last_index_week) {
        actual_year++
        let no_weeks = moment(new Date(actual_year,11,28)).isoWeek();
        weeks = createArrayWeek(no_weeks);
        index_week = parseInt(weeks.indexOf(actual_week));
        weekStart = moment(new Date(actual_year,0,4)).clone().startOf('isoweek').format('DD MM YYYY');
        weekEnd = moment(new Date(actual_year,0,4)).clone().endOf('isoweek').format('DD MM YYYY');
        budget.text(weeks[0] + ' ' + actual_year);
      }else {
        index_week++;
        weekStart = moment(new Date(actual_year,0,4)).add((index_week), 'weeks').clone().startOf('isoweek').format('DD MM YYYY');
        weekEnd = moment(new Date(actual_year,0,4)).add((index_week), 'weeks').clone().endOf('isoweek').format('DD MM YYYY');
        budget.text(weeks[index_week] + ' ' + actual_year);
      }
      span_text.text("Semana " + (index_week + 1) + " va del " + weekStart + " al " + weekEnd);
    }
  });
  
  function createArrayWeek(num_index) {
    let weeks = new Array(num_index);
    for (let index = 0; index < num_index; index++) {
      weeks[index] = "Semana " + (index + 1);
    }
    return weeks
  }

  $('#update-history-balance').click((event) => {
    let actual_budget = $('#actual-balance').text();
    let type_budget = $('#type-balance').val();
    let account_id = $('#account-name').val();
    let weekStart = "";
    let weekEnd = "";
    let actual_year = "";

    $('#history-line-title').text($('#account-name').text());

    switch (type_budget) {
      case '0':
        let no_weeks = moment(new Date(actual_budget,11,28)).isoWeek();
        weekStart = moment(new Date(actual_budget,0,4)).clone().startOf('isoweek').format('DD MM YYYY');

        $.ajax({
          url: "/pages/update_history_year",
          method: "POST",
          dataType: "json",
          data: {
            no_weeks: no_weeks,
            actual_budget: actual_budget,
            account_id: account_id,
            weekStart: weekStart
          },
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            var config = {
              type: "line",
              data: {
                labels: response['week_array'],
                datasets: [
                  {
                    label: actual_budget,
                    backgroundColor: "#00acdf",
                    borderColor: "#00acdf",
                    data: response['info_historial'],
                    fill: false,
                    barThickness: 8
                  }
                ]
              },
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: false,
                  text: "Sales Charts",
                  fontColor: "white"
                },
                legend: {
                  labels: {
                    fontColor: "white"
                  },
                  align: "end",
                  position: "bottom"
                },
                tooltips: {
                  mode: "index",
                  intersect: false
                },
                hover: {
                  mode: "nearest",
                  intersect: true
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,.7)"
                      },
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Month",
                        fontColor: "white"
                      },
                      gridLines: {
                        display: false,
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: "rgba(33, 37, 41, 0.3)",
                        zeroLineColor: "rgba(0, 0, 0, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2]
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,.7)"
                      },
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Value",
                        fontColor: "white"
                      },
                      gridLines: {
                        borderDash: [3],
                        borderDashOffset: [3],
                        drawBorder: false,
                        color: "rgba(255, 255, 255, 0.15)",
                        zeroLineColor: "rgba(33, 37, 41, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2]
                      }
                    }
                  ]
                }
              }
            };
            var ctx = document.getElementById("history-line-chart").getContext("2d");
            window.myLine.destroy();
            window.myLine = new Chart(ctx, config);
          }
        });
        break;

      case '1':
        actual_year = parseInt(budget.text().substr(-4));
        let actual_month = budget.text().replace(` ${actual_year}`, '');
        let index_month = parseInt(months.indexOf(actual_month));
        let no_days = new Date(actual_year, (index_month + 1), 0).getDate();
        weekStart = new Date(actual_year, index_month, 1);

        $.ajax({
          url: "/pages/update_history_month",
          method: "POST",
          dataType: "json",
          data: {
            no_days: no_days,
            actual_year: actual_year,
            index_month: index_month,
            account_id: account_id,
            weekStart: weekStart
          },
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            var config = {
              type: "line",
              data: {
                labels: response['days_array'],
                datasets: [
                  {
                    label: actual_budget,
                    backgroundColor: "#00acdf",
                    borderColor: "#00acdf",
                    data: response['info_historial'],
                    fill: false,
                    barThickness: 8
                  }
                ]
              },
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: false,
                  text: "Sales Charts",
                  fontColor: "white"
                },
                legend: {
                  labels: {
                    fontColor: "white"
                  },
                  align: "end",
                  position: "bottom"
                },
                tooltips: {
                  mode: "index",
                  intersect: false
                },
                hover: {
                  mode: "nearest",
                  intersect: true
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,.7)"
                      },
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Month",
                        fontColor: "white"
                      },
                      gridLines: {
                        display: false,
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: "rgba(33, 37, 41, 0.3)",
                        zeroLineColor: "rgba(0, 0, 0, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2]
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,.7)"
                      },
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Value",
                        fontColor: "white"
                      },
                      gridLines: {
                        borderDash: [3],
                        borderDashOffset: [3],
                        drawBorder: false,
                        color: "rgba(255, 255, 255, 0.15)",
                        zeroLineColor: "rgba(33, 37, 41, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2]
                      }
                    }
                  ]
                }
              }
            };
            var ctx = document.getElementById("history-line-chart").getContext("2d");
            window.myLine.destroy();
            window.myLine = new Chart(ctx, config);
          }
        });
        break;

      case '2':
        actual_year = parseInt(budget.text().substr(-4));
        let actual_week = budget.text().replace(` ${actual_year}`, '');
        let index_week = parseInt(weeks.indexOf(actual_week));
        weekStart = moment(new Date(actual_year,0,4)).add((index_week), 'weeks').clone().startOf('isoweek').format('DD MM YYYY');
        $.ajax({
          url: "/pages/update_history_week",
          method: "POST",
          dataType: "json",
          data: {
            account_id: account_id,
            weekStart: weekStart
          },
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            var config = {
              type: "line",
              data: {
                labels:[
                    'Lunes',
                    'Martes',
                    'Miercoles',
                    'Jueves',
                    'Viernes',
                    'Sabado',
                    'Domingo'
                  ],
                datasets: [
                  {
                    label: actual_budget,
                    backgroundColor: "#00acdf",
                    borderColor: "#00acdf",
                    data: response['info_historial'],
                    fill: false,
                    barThickness: 8
                  }
                ]
              },
              options: {
                maintainAspectRatio: false,
                responsive: true,
                title: {
                  display: false,
                  text: "Sales Charts",
                  fontColor: "white"
                },
                legend: {
                  labels: {
                    fontColor: "white"
                  },
                  align: "end",
                  position: "bottom"
                },
                tooltips: {
                  mode: "index",
                  intersect: false
                },
                hover: {
                  mode: "nearest",
                  intersect: true
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,.7)"
                      },
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Month",
                        fontColor: "white"
                      },
                      gridLines: {
                        display: false,
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: "rgba(33, 37, 41, 0.3)",
                        zeroLineColor: "rgba(0, 0, 0, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2]
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,.7)"
                      },
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "Value",
                        fontColor: "white"
                      },
                      gridLines: {
                        borderDash: [3],
                        borderDashOffset: [3],
                        drawBorder: false,
                        color: "rgba(255, 255, 255, 0.15)",
                        zeroLineColor: "rgba(33, 37, 41, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2]
                      }
                    }
                  ]
                }
              }
            };
            var ctx = document.getElementById("history-line-chart").getContext("2d");
            window.myLine.destroy();
            window.myLine = new Chart(ctx, config);
          }
        });
        break;
    }
    
  });
  
});