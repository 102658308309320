const { when } = require('jquery');

$(document).on('turbolinks:load', () => {
  $('.new-area-by-model').click(function(e) {
    e.preventDefault();
    let table_area_by_models = $(this).parent().parent().parent().parent().parent().find('tbody');
    let ip_unit_type_id, unit_name_id, unit_model_id, area, m2;
    $(this).parent().parent().find('input').each(function(index) {
      if(index == 5) {
        area = $(this).parent().find('.item').attr('data-value');
      }else{
        if(index == 2){
          ip_unit_type_id = $(this).val();
        }
        if(index == 3){
          unit_name_id = $(this).val();
        }
        if(index == 4) {
          unit_model_id = $(this).val();
        }
        if(index == 6) {
          m2 = $(this).val();
          $(this).val('');
        }
      }
    });
    $.ajax({
      url: "/area_by_models",
      method: "POST",  
      dataType: "json",
      data: {
        area_by_model: {
          ip_unit_type: ip_unit_type_id,
          unit_name: unit_name_id,
          unit_model: unit_model_id,
          area: area,
          m2: m2
        }
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        if(response['updated']) {
          table_area_by_models.find('.area_by_model_name').each(function() {
            if($(this).text().trim() == response['area_name']) {
              $(this).parent().parent().find('.area_by_model_m2').text(m2);
            }
          })
        }else {
          if (m2 == '0' || m2 == '') {
            m2 = 1;
          }

          table_area_by_models.append('<tr>'+
          '<td class="px-6 py-2 whitespace-nowrap">'+
            '<div class="area_by_model_name text-sm font-medium text-gray-900">'+
              response['area_name'] +
            '</div>'+
          '</td>'+
          '<td class="area_by_model_m2 px-6 py-2 whitespace-nowrap text-sm text-blue-700">'+
            m2 +
          '</td>'+
          '<td class="px-6 py-2 whitespace-nowrap text-sm text-red-700">'+
            '<span onclick="deleteAreaByModel(this)" class="text-red-500 text-sm font-bold bg-white mt-0.5 ml-2 -mr-2 cursor-pointer focus:outline-none">Eliminar</span>'+
          '</td>'+
          '</tr>');

          let containerUnit =  $(this).closest('.container-unit-model-areas');
          containerUnit.find('.area-button-show').removeClass('bg-red-500');
          containerUnit.find('.area-button-show').removeClass('border-red-500');

          containerUnit.find('.area-button-show').addClass('bg-green-500');
          containerUnit.find('.area-button-show').addClass('border-green-500');
        }
      }
    });

    return false;
  });
});