var $ = require('jquery');
var dt = require('datatables.net-dt');
const { forEach } = require('jszip');
window.JSZip = require('jszip');
require('pdfmake');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/dataTables.buttons.min')();
require('datatables.net-buttons/js/buttons.flash.min')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-rowgroup-dt');

$(document).on('turbolinks:load', () => {
  var img64 = window.enterpriseLogo;
  var numFile = 1; 
  var isOtherPage = true;
  var titleTable = "";
  var titlePlanning = "";
  var currentdate = new Date();
  var columnsVisible = range(0,12);
  var datetime = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/"
              + currentdate.getFullYear();
              
  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          if(column > 0) {
            node = $(node).find(".data-value");
            return node.is("input") ? node.val() : node.text().trim();
          }else{
            if ( $("#expenses-flow-table").length ) { 
              node = $(node).find(".data-value");
              return node.text().trim();
            }else {
              return row + 1;
            }
          }
        }
      }
    }
  };

  if ( $("#incomes-per-unit").length ) { 
    seedName = $("#seed-name").val();
    titleTable = "Flujo de ingresos por Venta por unidad";
    titlePlanning = `Planeacion de ingresos: Sembrado ${seedName}.` 
  }
  if ( $("#incomes-by-type-payment").length ) {
    seedName = $("#seed-name").val(); 
    titleTable = "Flujo de ingresos por Venta por tipo de pago";
    titlePlanning = `Planeacion de ingresos: Sembrado ${seedName}.` 
  }
  if ( $("#expenses-flow-table").length ) { 
    expensesPlanning = $("#expenses-planning-name").val();
    titleTable = "Flujo de egresos";
    titlePlanning = `Planeacion de egresos: ${expensesPlanning}.`
  }
  if ( $("#sales-per-unit").length ) { 
    titleTable = "Cobranza mensual por cliente";
  }

  var table = $('#table-expenses-flow').DataTable({
    responsive: true,
    "language": {
      "lengthMenu": "Mostrar _MENU_ registros por página",
      "zeroRecords": "Nada encontrado",
      "info": "Mostrando página _PAGE_ de _PAGES_",
      "infoEmpty": "No hay registros disponibles",
      "infoFiltered": "(filtrado de _MAX_ registros)",
      "sSearch": "Buscar:",
      "oPaginate": {
        "sFirst": "Primero",
        "sPrevious": "Anterior",
        "sNext": "Siguiente",
        "sLast": "Último"
      }
    },
    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
    dom: 'Blftip',
    "drawCallback":function(){
      index = 1
      var numberOfColumns = $('#table-expenses-flow thead th').length;
      if ( $("#sales-per-unit").length ) { index = 3 }
      if ( $("#expenses-flow-table").length ) { index = 2 }
      if ( $("#incomes-flow-sales-table").length || $("#sales-per-unit").length || $("#expenses-flow-table").length ) { numberOfColumns -= 1; }
      
      var api = this.api()
      $(api.column(0).footer()).html('Total: ')
      for (index; index <= numberOfColumns; index++) {
        dataArray =  api.column(index, {page:'current'}).nodes() 
        $(api.column(index).footer()).html('  ' +
          sum(dataArray).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '  '
        )
      }
    },
    buttons: [
      $.extend( true, {}, buttonCommon, {
          extend: 'copyHtml5'
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'excelHtml5',
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
         
            $('row:eq(0) c', sheet).attr( 's', '50' );
            $('row:eq(1) c', sheet).attr( 's', '50' );
            $('row:last c', sheet).attr('s','57');
          },
          title: titleTable,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      ${titlePlanning}\n
                      Fecha de exportación: ${datetime}\n
                      Rango de Fecha: ${$("#range-months").val()}\n`,
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'pdfHtml5',
          footer: true,
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: function() {
            return `${titleTable}_${numFile}`;
          },
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.
                      ${titlePlanning}
                      Fecha de exportación: ${datetime}
                      Rango de Fecha: ${$("#range-months").val()}`,
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisible) < 0) {
                return false;
              }
              return true;
            },
            stripHtml: false,
            stripNewlines: false
          },
          customize: function ( doc ) {
            doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};
            doc.styles.tableFooter.fontSize = 8;

            for (let i = 0; i <  doc.content[2].table.body[0].length; i++) {
              doc.content[2].table.body[0][i].text = doc.content[2].table.body[0][i].text.replace("<br>","\n");
            }

            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
      } )
    ],
  });
  var  columnsLengthTable = table.columns().count();

  $(".buttons-pdf").on("click", function () {
    if (columnsLengthTable > 11) {
      if(isOtherPage) {
        numLaps = 1;
        if ( (columnsVisible[columnsVisible.length -1] + 12) < columnsLengthTable) {
          if ( $("#expenses-flow-table").length ) {
            columnsLength = columnsVisible.length + 11;
          }else{
            columnsLength = columnsVisible.length + 12;
          }
          numFile++;
        }else{
          columnsLength = columnsVisible[columnsVisible.length -1] + (columnsLengthTable - columnsVisible[columnsVisible.length -1])
          isOtherPage = false;
          numFile++;
        }
        columnInitial = columnsVisible[columnsVisible.length -1] + 1;
        columnsVisible = []
        for (let i = columnInitial; i < columnsLength; i++) {
          if (numLaps <= 1) {
            if ( $("#expenses-flow-table").length ) { 
              columnsVisible.push(0);
              columnsVisible.push(1);
            } else {
              columnsVisible.push(0);
            }
            i -= 1;
            numLaps = 2;
          }else{
          columnsVisible.push(i);
          }
        }
        table.buttons('.buttons-pdf').trigger();
      }else{
        isOtherPage = true;
        columnsVisible = range(0,12);
        numFile = 1;
      }
    }
  });

  $('#table-expenses-flow tbody').on('click', 'tr.dtrg-group', function () {
    var name = $(this).data('name');
    collapsedGroups[name] = !collapsedGroups[name];
    table.draw(false);
  });

  $('.show-expenses-detailed').click((e) => {
    e.preventDefault();
    showVisibilityAnimation($('#modal-expenses-detailed'));
  });

  $('.close-modal-expenses-detailed').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-expenses-detailed');
  });

  $('.other-cost-sales').keyup(function() {
    calculateOtherCostSales(this);
  });

  function calculateOtherCostSales(element) {
    let otherCostSales = 0
    if ($(element).val() != '') {
      let value_other_cost_sales = $(element).val().split('$').join('').split(',').join('');
      otherCostSales = parseFloat(value_other_cost_sales);
    }
    let commissions = parseFloat($('.commisions-sale').val().split('$').join('').split(',').join(''));
    let guaranteed = parseFloat($('.guaranteed-sale').val().split('$').join('').split(',').join(''));
    
    let costSales = (commissions + guaranteed + otherCostSales).toFixed(2);
    $('.cost-sales').val(parseFloat(costSales, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
    let sale = parseFloat($('.sale').val().split('$').join('').split(',').join(''));
    let netSale = (sale - costSales).toFixed(2)
    $('.net-sales').val(parseFloat(netSale, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
    calculateValues();
    calculateNetValues();
  };

  if($('.other-cost-sales').length) {
    calculateOtherCostSales($('.other-cost-sales'));
  }

  $('.hardcost, .softcost, .land_value').keyup(function() {
    calculateValues();
    calculateNetValues();
  });

  $('.financial-costs, .taxes').keyup(function() {
    calculateNetValues();
    calculateValues();
  });

  function calculateNetValues() {
    let utilityBeforeTaxes = 0;
    let financialCosts = 0;
    let taxes = 0;
    let netUtility = 0;

    if ($('.utility-before-taxes').val() != '') {
      utilityBeforeTaxes = parseFloat($('.utility-before-taxes').val().split('$').join('').split(',').join(''));
    }

    if ($('.financial-costs').val() != '') {
      let financial_costs = $('.financial-costs').val().split('$').join('').split(',').join('');
      financialCosts = parseFloat(financial_costs);
    }
    if ($('.taxes').val() != '') {
      let taxes_value = $('.taxes').val().split('$').join('').split(',').join('');
      taxes = parseFloat(taxes_value);
    }
    
    netUtility = (utilityBeforeTaxes - financialCosts - taxes).toFixed(2);
    
    $('.net-utility').val(parseFloat(netUtility, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
  }

  function calculateValues() {
    let hardcost = 0;
    let softcost = 0;
    let land_value = 0;
    let netSales = 0;

    if ($('.hardcost').val() != '') {
      let hardcost_value = $('.hardcost').val().split('$').join('').split(',').join('');
      hardcost = parseFloat(hardcost_value);
    }
    if ($('.softcost').val() != '') {
      let softcost_value = $('.softcost').val().split('$').join('').split(',').join('');
      softcost = parseFloat(softcost_value);
    }
    if ($('.land_value').val() != '') {
      let land_value_value = $('.land_value').val().split('$').join('').split(',').join('');
      land_value = parseFloat(land_value_value);
    }

    let costTotal = (hardcost + softcost + land_value).toFixed(2);

    if ($('.net-sales').val() != '') {
      netSales = parseFloat($('.net-sales').val().split('$').join('').split(',').join(''));
    }
  
    $('.cost-total').val(parseFloat(costTotal, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    $('.utility-before-taxes').val(parseFloat((netSales - costTotal), 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
  }
  
  $('.close-modal-payment-expenses').click((e) => {
    e.preventDefault();
    $('.modal-add-payments').addClass('invisible');
    $('.modal-add-payments').addClass('opacity-0');
  });

  $('.form-subcategory').submit(function(e) {
    e.preventDefault();

    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    let data = $(this).serializeArray();
    let information;

    if(data[5] != null){
      information = {
        authenticity_token: AUTH_TOKEN,
        expense_planning_detail: {
          expense_planning: data[1]['value'],
          category: data[2]['value'],
          name: data[3]['value'],
          amount: data[4]['value'],
          is_positive: data[5]['value'],
        },
        total_category: data[0]['value'],
      };      
    }else{
      if(data[4]!= null){
          information = {
          authenticity_token: AUTH_TOKEN,
          expense_planning_detail: {
            expense_planning: data[1]['value'],
            category: data[2]['value'],
            name: data[3]['value'],
            amount: data[4]['value'],
          },
          total_category: data[0]['value'],
        }
      }else{
        information = {
          authenticity_token: AUTH_TOKEN,
          expense_planning_detail: {
            expense_planning: data[0]['value'],
            category: data[1]['value'],
            name: data[2]['value'],
            amount: data[3]['value'],
          }
        }
      }
    }

    input = $(this).children(".input-sub-category")
    select = $(this).children(".select-sub-category")
    selectSubCategory = select[0].selectize

    $.ajax({
      url: "/expense_planning_details",
      method: "POST",
      dataType: "json",
      data: information,
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        if(response["success"] != false){
          let category_name = response['category'];
          let list_category;
          let category_section;

          if(category_name == 'Otros costos de venta') {
            list_category = 'list_other_cost_sales';
            category_section = 'other_cost_sales';
          }
          if(category_name == 'Hardcost') {
            list_category = 'list_hardcosts';
            category_section = 'hardcost';
          }
          if(category_name == 'Softcost') {
            list_category = 'list_softcosts';
            category_section = 'softcost';
          }
          if(category_name == 'Valor del terreno') {
            list_category = 'list_land_values';
            category_section = 'land_value';
          }
          if(category_name == 'Impuestos') {
            list_category = 'list_taxes';
            category_section = 'taxes';
          }
          if(category_name == 'Costos financieros') {
            list_category = 'list_financial_costs';
            category_section = 'financial_costs';
          }
          if(category_name == 'Otros egresos') {
            list_category = 'list_other_expenses';
          }

          $(`#${category_section}_real`).val(response['real_amount'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
          $(`#${category_section}_difference`).val(response['difference_amount'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));

          $(`.${list_category}`).empty();
          response['expense_details'].forEach(function(currentValue, index) {
            addDetail(list_category, currentValue[3], currentValue[2], currentValue[0]['$oid'], currentValue[4])
          });
          selectSubCategory.setValue("")
          input.val("");
        }else{
          Swal.fire(
            'Alerta',
            'No es posible hacer la captura/actualizar debido que supera el monto total de la categoria.',
            'warning'
          )
        }
      }
    });

    return false;
  });

  function addDetail(list, name, amount, id, is_positive) {
    if(is_positive == true) { 
      sign = '+';
    }else {
      sign = '-';
    }

    if(list == 'list_financial_costs') {
      $(`.${list}`).append('' +
      '<li class="mb-2 border-2 border-gray-900 rounded-md pl-3 pr-4 py-1 flex items-center justify-between text-sm">'+
        '<div class="w-0 flex-1 flex items-center">'+
          '<span class="ml-2 flex-1 w-0 truncate">'+
            name +
          '</span>'+
        '</div>'+
        '<span class="flex-1 w-1 text-right mr-2 font-bold">'+
          sign +
        '</span>'+
        '<input type="text"'+
          'class="border rounded-sm p-1 bg-white text-center w-1/3 border-gray-400 focus:outline-none"'+
          'value="'+ parseFloat(amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'">'+
        '<div class="ml-4 flex flex-shrink-0">'+
          '<span id="'+ id +'"'+ 'onclick="deleteDetail(this)"' +
            'class="ml-2 flex font-medium text-red-600 duration-500 hover:text-red-500">'+
            '<svg class="cursor-pointer w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"'+
              'fill="currentColor">'+
              '<path fill-rule="evenodd"'+
                'd="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"'+
                'clip-rule="evenodd" />'+
            '</svg>'+
          '</span>'+
        '</div>'+
      '</li>'+
      '');
    }else{
      $(`.${list}`).append('' +
      '<li class="mb-2 border-2 border-gray-900 rounded-md pl-3 pr-4 py-1 flex items-center justify-between text-sm">'+
        '<div class="w-0 flex-1 flex items-center">'+
          '<span class="ml-2 flex-1 w-0 truncate">'+
            name +
          '</span>'+
        '</div>'+
        '<input type="text"'+
          'class="border rounded-sm p-1 bg-white text-center w-1/3 border-gray-400 focus:outline-none"'+
          'value="'+ parseFloat(amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) +'">'+
        '<div class="ml-4 flex flex-shrink-0">'+
          '<span id="'+ id +'"'+ 'onclick="deleteDetail(this)"' +
            'class="ml-2 flex font-medium text-red-600 duration-500 hover:text-red-500">'+
            '<svg class="cursor-pointer w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"'+
              'fill="currentColor">'+
              '<path fill-rule="evenodd"'+
                'd="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"'+
                'clip-rule="evenodd" />'+
            '</svg>'+
          '</span>'+
        '</div>'+
      '</li>'+
      '');
    }
  }

  const showVisibilityAnimation = (modalName) => {
    modalName.css('visibility', 'visible').animate({ opacity: 1 });
  }

  const hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);

    modal.css("visibility", "hidden");
    modal.css("opacity", "0")
  };

  $('.change-status-expense-planning').click(function() {
    let expense_planning_id = $(this).parent().find('.expense-planning-id').val();

    $('#expense_planning_expense_planning_id_update').val(expense_planning_id);
    $('#modal-status-expense-planning').toggle();
  });

  function sum(data) { 
    return data.flatten().reduce( function ( a, b ) {
      if ( typeof b != 'string' && $(b).find("span") ) {
        b = $(b).find("span").text();
      }      
      if ( typeof a === 'string' ) {
        a = a.replace(/[^\d.-]/g, '') * 1;
      }
      if ( typeof b === 'string' ) {
        b = b.replace(/[^\d.-]/g, '') * 1;
      }
      return a + b;
    }, 0 );
  }
})

function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx);
}

