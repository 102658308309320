$(document).on('turbolinks:load', () => {
  if ($('.select-income-enterprise').length) {

    function incompleteFields() {
      Swal.fire(
        'Campos obligatorios incompletos',
        'Llene los datos obligatorios para continuar.',
        'warning'
      )
    }

    function verifyFields(e) {
      e.preventDefault();
      
      let enterprise_r, project_r, account_r, amount_r, unity_r, selectCategory, currentValue, containerCategory, optionSelected, groupSelected;
      let type_record = $('.record-type-record')[0].selectize.getValue();
      switch (type_record) {
        case '1':
          enterprise_r = $('.select-income-enterprise')[0].selectize.getValue();
          project_r = $('.select-income-project')[0].selectize.getValue();
          account_r = $('.select-income-account')[0].selectize.getValue();
          amount_r = $('.record-income-amount').val();

          if(enterprise_r == '' || project_r == '' || account_r == '' || amount_r == '') {
            incompleteFields();
            return false;
          }

          selectCategory = $(`.select-income-category`)[0].selectize;
          currentValue = selectCategory.getValue();
          if(currentValue == '') {
            incompleteFields();
            return false;
          }
          containerCategory = selectCategory.getItem(selectCategory.getValue())[0].parentNode.parentNode;
          optionSelected = $(containerCategory).find("[data-value='" + currentValue + "']"); 
    
          groupSelected = $(optionSelected.parent()[1]).attr("data-group");
          if( groupSelected == 'Ingresos por venta' && enterprise_r == 'inmobiliary_projects') {
            unity_r = $('.select-income-unity')[0].selectize.getValue();
            if(unity_r == '') {
              incompleteFields();
              return false;
            }
          }
          break;
        case '2':
          enterprise_r = $('.select-expense-enterprise')[0].selectize.getValue();
          project_r = $('.select-expense-project')[0].selectize.getValue();
          account_r = $('.select-expense-account')[0].selectize.getValue();
          amount_r = $('.record-expense-amount').val();

          if(enterprise_r == '' || project_r == '' || account_r == '' || amount_r == '') {
            incompleteFields();
            return false;
          }

          selectCategory = $(`.select-expense-category`)[0].selectize;
          currentValue = selectCategory.getValue();
          if(currentValue == '') {
            incompleteFields();
            return false;
          }
          containerCategory = selectCategory.getItem(selectCategory.getValue())[0].parentNode.parentNode;
          optionSelected = $(containerCategory).find("[data-value='" + currentValue + "']"); 
    
          groupSelected = $(optionSelected.parent()[1]).attr("data-group");

          if( groupSelected == 'Costos de venta' && enterprise_r == 'inmobiliary_projects') {
            unity_r = $('.select-expense-unity')[0].selectize.getValue();
            if(unity_r == '') {
              incompleteFields();
              return false;
            }
          }

          //REINGRESO
          if ($('#reentry-expense-activated').is(":checked") == true) {
            enterprise_r = $('.select-re-income-enterprise')[0].selectize.getValue();
            project_r = $('.select-re-income-project')[0].selectize.getValue();
            account_r = $('.select-re-income-account')[0].selectize.getValue();

            if(enterprise_r == '' || project_r == '' || account_r == '') {
              incompleteFields();
              return false;
            }

            selectCategory = $(`.select-re-income-category`)[0].selectize;
            currentValue = selectCategory.getValue();
            if(currentValue == '') {
              incompleteFields();
              return false;
            }
            containerCategory = selectCategory.getItem(selectCategory.getValue())[0].parentNode.parentNode;
            optionSelected = $(containerCategory).find("[data-value='" + currentValue + "']"); 
      
            groupSelected = $(optionSelected.parent()[1]).attr("data-group");
            if( groupSelected == 'Ingresos por venta' && enterprise_r == 'inmobiliary_projects') {
              unity_r = $('.select-re-income-unity')[0].selectize.getValue();
              if(unity_r == '') {
                incompleteFields();
                return false;
              }
            }
          }
          break;
      }
      return true;
    }

    $('#save-record').submit(function(e) {
      let verifyCondition = verifyFields(e);
      if(verifyCondition == false){
        return false
      }
    });

    $('#update-record').submit(function(e) {
      let verifyCondition = verifyFields(e);
      if(verifyCondition == false){
        return false
      }
    });

    $('.record-income-amount').keyup(function() {
      let amount = $(this).val().split('$').join('').split(',').join('');
      if($('#iva-income-activated').is(":checked") == true) {
        if( $('.iva-income-type').val() == '%' ) {
          amount = parseFloat(amount, 10) / (1 + ($('.iva-income-amount').val() / 100));
        }
        if($('.iva-income-type').val() == '$' ) {
          amount = parseFloat(amount, 10) - (($('.iva-income-amount').val()));
        }
      }
      $('.subtotal-income').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    });

    $('.record-expense-amount').keyup(function() {
      let amount = $(this).val().split('$').join('').split(',').join('');
      if($('#iva-expense-activated').is(":checked") == true) {
          if( $('.iva-expense-type').val() == '%' ) {
            amount = parseFloat(amount, 10) / (1 + ($('.iva-expense-amount').val() / 100));
          }
          if($('.iva-expense-type').val() == '$' ) {
            amount = parseFloat(amount, 10) - (($('.iva-expense-amount').val()));
          }
      }
      $('.subtotal-expense').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    });

    $('.iva-income-amount').keyup(function() {
      let amount = $('.record-income-amount').val().split('$').join('').split(',').join('');
      if($('#iva-income-activated').is(":checked") == true) {
          if( $('.iva-income-type').val() == '%' ) {
            amount = parseFloat(amount, 10) / (1 + ($('.iva-income-amount').val() / 100));
          }
          if($('.iva-income-type').val() == '$' ) {
            amount = parseFloat(amount, 10) - (($('.iva-income-amount').val()));
          }
      }
      $('.subtotal-income').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    });

    $('.iva-expense-amount').keyup(function() {
      let amount = $('.record-expense-amount').val().split('$').join('').split(',').join('');
      if($('#iva-expense-activated').is(":checked") == true) {
          if( $('.iva-expense-type').val() == '%' ) {
            amount = parseFloat(amount, 10) / (1 + ($('.iva-expense-amount').val() / 100));
          }
          if($('.iva-expense-type').val() == '$' ) {
            amount = parseFloat(amount, 10) - (($('.iva-expense-amount').val()));
          }
      }
      $('.subtotal-expense').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    });

    $('.iva-income-type').change(function() {
      verifyIncomeIVA();
    });

    $('.iva-expense-type').change(function() {
      verifyExpenseIVA();
    });

    $('#iva-income-activated:checkbox').change(function(){
      if($('#iva-income-activated').is(":checked") == true) {
        verifyIncomeIVA();
      }else{
        let amount = $('.record-income-amount').val().split('$').join('').split(',').join('');
        $('.subtotal-income').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
      }
    });

    $('#iva-expense-activated:checkbox').change(function(){
      if($('#iva-expense-activated').is(":checked") == true) {
        verifyExpenseIVA();
      }else{
        let amount = $('.record-expense-amount').val().split('$').join('').split(',').join('');
        $('.subtotal-expense').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
      }
    });

    function verifyIncomeIVA() {
      let amount = $('.record-income-amount').val().split('$').join('').split(',').join('');
      if($('#iva-income-activated').is(":checked") == true) {
          if( $('.iva-income-type').val() == '%' ) {
            amount = parseFloat(amount, 10) / (1 + ($('.iva-income-amount').val() / 100));
          }
          if($('.iva-income-type').val() == '$' ) {
            amount = parseFloat(amount, 10) - (($('.iva-income-amount').val()));
          }
      }
      $('.subtotal-income').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    }

    function verifyExpenseIVA() {
      let amount = $('.record-expense-amount').val().split('$').join('').split(',').join('');
      if($('#iva-expense-activated').is(":checked") == true) {
          if( $('.iva-expense-type').val() == '%' ) {
            amount = parseFloat(amount, 10) / (1 + ($('.iva-expense-amount').val() / 100));
          }
          if($('.iva-expense-type').val() == '$' ) {
            amount = parseFloat(amount, 10) - (($('.iva-expense-amount').val()));
          }
      }
      $('.subtotal-expense').val(parseFloat(amount,10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
    }

    $(".select-income-enterprise")[0].selectize.off()
    $(".select-income-enterprise")[0].selectize.on("change", function (event) {
      setProjectsByEnterprise('income');
    });

    $(".select-expense-enterprise")[0].selectize.off()
    $(".select-expense-enterprise")[0].selectize.on("change", function (event) {
      setProjectsByEnterprise('expense');
      showExpenseSelectEnterprise();
    });

    $(".select-re-income-enterprise")[0].selectize.off()
    $(".select-re-income-enterprise")[0].selectize.on("change", function (event) {
      setProjectsByEnterprise('re-income');
    });

    function setProjectsByEnterprise(type) {
      let enterprise = $(`.select-${type}-enterprise`)[0].selectize.getValue();
      $.ajax({
        url: "/records/projects",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            enterprise: enterprise
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          let $select1 = $(document.getElementsByClassName(`select-${type}-project`)[0]);

          let selectize1 = $select1[0].selectize;

          selectize1.setValue(null);
          selectize1.clearOptions();

          if(enterprise != 'inmobiliary_projects') {
            selectize1.addOption({ value: 'oficina', text: 'Oficina' });
          }

          response['values'].forEach(element => {
            selectize1.addOption({ value: element[1]['$oid'], text: element[0] });
          });

          selectize1.refreshOptions();

          if($('#edit-view-record').length){
            if(type == 're-income'){
              project = $('#re_income_project_id').text();
            }else{
              project = $('#project_id').text();
            }
            selectize1.setValue(project);
            selectize1.close();
            selectize1.close();
          }
        }
      });
      
      $(`.${type}-section-enterprise`).show();
      let category = $(`#select-${type}-category :selected`).parent().attr('value');
      switch (enterprise) {
        case 'project':
          if (category == 'base') {
            $(`.${type}-section-category-sale`).show();
          }
          break;

        default:
          break;
      }
    };

    /* INCOME */
    $(".select-income-project")[0].selectize.off()
    $(".select-income-project")[0].selectize.on("change", function (event) {
      let selected = $('.select-income-project').children("option:selected").val();

      let selectOptions = $('.select-income-project').find('.selectize-dropdown-content .option');
      selectOptions.removeClass('selected');

      let currentSelectedOption = $('.select-income-project').find('.selectize-dropdown-content .option[data-value="' + selected + '"]');
      currentSelectedOption.addClass('selected');

      /* GET CATEGORIES AND SUBCATEGORIES */
      let enterprise = $(`.select-income-enterprise`)[0].selectize.getValue();
      let project = $(`.select-income-project`)[0].selectize.getValue();
      let recordId = $('#record_record_id').val();

      $.ajax({
        url: "/records/categories",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            enterprise: enterprise,
            project: project,
            recordId: recordId,
            type: 'income'
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          let enterprise = $(`.select-income-enterprise`)[0].selectize.getValue();

          let $select1 = $(document.getElementsByClassName('select-income-category')[0]);
          let selectize1 = $select1[0].selectize;

          selectize1.setValue(null);
          selectize1.clearOptions();

          if(enterprise == 'inmobiliary_projects') {
            selectize1.addOptionGroup('Ingresos por venta', {label: 'Ingresos por venta'});
            selectize1.addOption({
              text: 'Preventas',
              value: 'preventas',
              optgroup: 'Ingresos por venta'
            });
            selectize1.addOption({
              text: 'Saldos',
              value: 'saldos',
              optgroup: 'Ingresos por venta'
            });
          }

          response['values'].forEach((element, index) => {
            for (const category in element) {
              value = element[category]
              if (category != 'Ingresos por venta' || enterprise != 'inmobiliary_projects') {
                categoryLabel = correctingFinancialCosts(category);
                selectize1.addOptionGroup(category, {label: categoryLabel});
              }
              for (const subcategory in value) {
                selectize1.addOption({
                  text: value[subcategory][0],
                  value: value[subcategory][1]['$oid'],
                  optgroup: category
                });
              }
            }
          });

          //response['values'].forEach(element => {
          //  selectize1.addOption({ value: element[1]['$oid'], text: element[0] });
          //});

          selectize1.refreshOptions();
          if($('#edit-view-record').length){
            let category = $('#record_category_id').text();
            selectize1.close();
            selectize1.setValue(category);
          }
        }
      });
      /* GET CATEGORIES AND SUBCATEGORIES */

      $('.income-section-project').show();
    });

    $(".select-income-category")[0].selectize.off()
    $(".select-income-category")[0].selectize.on("change", function (event) {
      let selectizeEnterprise = $(document.getElementsByClassName(`select-income-enterprise`)[0]);
      let enterprise = selectizeEnterprise.val();

      let selectCategory = $(`.select-income-category`)[0].selectize;
      $('.income-section-category').show();
      let currentValue = selectCategory.getValue();

      if (currentValue != '') {
        let containerCategory = selectCategory.getItem(selectCategory.getValue())[0].parentNode.parentNode;
        let optionSelected = $(containerCategory).find("[data-value='" + currentValue + "']"); 
  
        let groupSelected = $(optionSelected.parent()[1]).attr("data-group");
        switch (groupSelected) {
          case 'Ingresos por venta': //AQUÍ SOLO ESTÁ EL "INGRESOS POR VENTA"
            if (enterprise == 'inmobiliary_projects') {
              let enterprise = $(`.select-income-enterprise`)[0].selectize.getValue();
              let project = $(`.select-income-project`)[0].selectize.getValue();
              $.ajax({
                url: "/records/units",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select1 = $(document.getElementsByClassName('select-income-unity')[0]);
                  let $select2 = $(document.getElementsByClassName('select-income-account')[0]);
                  let selectize1 = $select1[0].selectize;
                  let selectize2 = $select2[0].selectize;
  
                  selectize1.setValue(null);
                  selectize1.clearOptions();
                  selectize2.setValue(null);
                  selectize2.clearOptions();
  
                  response['values'].forEach((element, index) => {
                    selectize1.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });
  
                  response['accounts'].forEach((element, index) => {
                    selectize2.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });
  
                  selectize1.refreshOptions();
                  //selectize2.refreshOptions();
                  
                  selectize1.close();
                  selectize2.close();
                  if($('#edit-view-record').length){
                    let unit = $('#record_unit_id').text();
                    let account = $('#record_account_id').text();
  
                    selectize1.setValue(unit);
                    selectize1.close();
                    selectize2.setValue(account);
                    selectize2.close();
                    $('#check-finished').trigger("change");
                  }
                  $('.income-section-category-sale').show();
                  $('.income-category-sale').hide();
                }
              });
            }else{
              enterprise = $(`.select-income-enterprise`)[0].selectize.getValue();
              project = $(`.select-income-project`)[0].selectize.getValue();
              $.ajax({
                url: "/records/units",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select3 = $(document.getElementsByClassName('select-income-account')[0]);
                  let selectize3 = $select3[0].selectize;
  
                  selectize3.setValue(null);
                  selectize3.clearOptions();
  
                  response['accounts'].forEach((element, index) => {
                    selectize3.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });
                  selectize3.close();

                  $('.client-income').val(response['client'][1]);
                  $('.client-id-income').val(response['client'][0]['$oid']);
  
                  if($('#edit-view-record').length){
                    let account = $('#record_account_id').text();
                    selectize3.setValue(account);
                    selectize3.close();
                    $('#check-finished').trigger("change");
                  };
                  
                  if (project != 'oficina') {
                    $('.income-category-sale').show();
                  }
                  $('.income-section-category-sale').hide();
                }
              });
            }
            break;
  
          default:
            enterprise = $(`.select-income-enterprise`)[0].selectize.getValue();
            project = $(`.select-income-project`)[0].selectize.getValue();
            $.ajax({
              url: "/records/units",
              method: "POST",
              dataType: "json",
              data: {
                record: {
                  enterprise: enterprise,
                  project: project,
                }
              },
              error: function (xhr, status, error) {
                console.error('AJAX Error: ' + status + error);
              },
              success: function (response) {
                let $select3 = $(document.getElementsByClassName('select-income-account')[0]);
                let selectize3 = $select3[0].selectize;
  
                selectize3.setValue(null);
                selectize3.clearOptions();
  
                response['accounts'].forEach((element, index) => {
                  selectize3.addOption({
                    value: element[0]['$oid'],
                    text: element[1],
                  });
                });
                selectize3.close();
                if($('#edit-view-record').length){
                  let account = $('#record_account_id').text();
  
                  selectize3.close();
                  selectize3.setValue(account);
                  $('#check-finished').trigger("change");
                };
                $('.income-section-category-sale').hide();
                $('.income-category-sale').hide();
              }
            });
            break;
        }
      }

      $('#submit-record').css('display', 'flex');
    });

    $(".select-income-unity")[0].selectize.off()
    $(".select-income-unity")[0].selectize.on("change", function (event) {
      let selectCategory = $(`.select-income-unity`)[0].selectize;
      let currentValue = selectCategory.getValue();
      
      $.ajax({
        url: "/records/client_by_unit",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            seed_information: currentValue,
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('.client-income-unity').val(response['values']);
          $('.client-id-income-unity').val(response['client_id']);
        }
      });
    });

    $(".select-re-income-project")[0].selectize.off()
    $(".select-re-income-project")[0].selectize.on("change", function (event) {
      let selected = $('.select-re-income-project').children("option:selected").val();

      /* GET CATEGORIES AND SUBCATEGORIES */
      let enterprise = $(`.select-re-income-enterprise`)[0].selectize.getValue();
      let project = $(`.select-re-income-project`)[0].selectize.getValue();
      let recordId = $('#record_record_id').val();

      $.ajax({
        url: "/records/categories",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            enterprise: enterprise,
            project: project,
            recordId: recordId,
            type: 'income'
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          let enterprise = $(`.select-re-income-enterprise`)[0].selectize.getValue();

          let $select1 = $(document.getElementsByClassName('select-re-income-category')[0]);
          let selectize1 = $select1[0].selectize;

          selectize1.setValue(null);
          selectize1.clearOptions();

          if(enterprise == 'inmobiliary_projects') {
            selectize1.addOptionGroup('Ingresos por venta', {label: 'Ingresos por venta'});
            selectize1.addOption({
              text: 'Preventas',
              value: 'preventas',
              optgroup: 'Ingresos por venta'
            });
            selectize1.addOption({
              text: 'Saldos',
              value: 'saldos',
              optgroup: 'Ingresos por venta'
            });
          }

          response['values'].forEach((element, index) => {
            for (const category in element) {
              value = element[category]
              if (category != 'Ingresos por venta' || enterprise != 'inmobiliary_projects') {
                categoryLabel = correctingFinancialCosts(category);
                selectize1.addOptionGroup(category, {label: categoryLabel});
              }
              for (const subcategory in value) {
                selectize1.addOption({
                  text: value[subcategory][0],
                  value: value[subcategory][1]['$oid'],
                  optgroup: category
                });
              }
            }
          });

          selectize1.refreshOptions();
          if($('#edit-view-record').length){
            let category = $('#re_income_record_category_id').text();

            selectize1.setValue(category);
            selectize1.close();
          }
        }
      });
    });

    $(".select-re-income-category")[0].selectize.off()
    $(".select-re-income-category")[0].selectize.on("change", function (event) {
      let selectizeEnterprise = $(document.getElementsByClassName(`select-re-income-enterprise`)[0]);
      let enterprise = selectizeEnterprise.val();

      let selectCategory = $(`.select-re-income-category`)[0].selectize;
      $('.income-re-section-category').show();
      let currentValue = selectCategory.getValue();

      if ( currentValue != '' ) {
        let containerCategory = selectCategory.getItem(selectCategory.getValue())[0].parentNode.parentNode;
        let optionSelected = $(containerCategory).find("[data-value='" + currentValue + "']"); 

        let groupSelected = $(optionSelected.parent()[1]).attr("data-group");

        switch (groupSelected) {
          case 'Ingresos por venta': //AQUÍ SOLO ESTÁ EL "INGRESOS POR VENTA"
            if (enterprise == 'inmobiliary_projects') {
              let enterprise = $(`.select-re-income-enterprise`)[0].selectize.getValue();
              let project = $(`.select-re-income-project`)[0].selectize.getValue();
              $.ajax({
                url: "/records/units",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select1 = $(document.getElementsByClassName('select-re-income-unity')[0]);
                  let $select2 = $(document.getElementsByClassName('select-re-income-account')[0]);
                  let selectize1 = $select1[0].selectize;
                  let selectize2 = $select2[0].selectize;

                  selectize1.setValue(null);
                  selectize1.clearOptions();
                  selectize2.setValue(null);
                  selectize2.clearOptions();

                  response['values'].forEach((element, index) => {
                    selectize1.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });

                  response['accounts'].forEach((element, index) => {
                    selectize2.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });

                  selectize1.refreshOptions();
                  //selectize2.refreshOptions();
                  
                  selectize1.close();
                  selectize2.close();

                  if($('#edit-view-record').length){
                    let unit = $('#re_income_record_unit_id').text();
                    selectize1.setValue(unit);
                    selectize1.close();

                    let account = $('#re_income_record_account_id').text();
                    selectize2.setValue(account);
                    selectize2.close();
                  }

                  $('.re-income-section-category-sale').show();
                  $('.re-income-category-sale').hide();
                }
              });
            }else{
              enterprise = $(`.select-re-income-enterprise`)[0].selectize.getValue();
              project = $(`.select-re-income-project`)[0].selectize.getValue();
              $.ajax({
                url: "/records/units",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select3 = $(document.getElementsByClassName('select-re-income-account')[0]);
                  let selectize3 = $select3[0].selectize;

                  selectize3.setValue(null);
                  selectize3.clearOptions();

                  response['accounts'].forEach((element, index) => {
                    selectize3.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });
                  selectize3.close();

                  $('.client-re-income').val(response['client'][1]);
                  $('.client-id-re-income').val(response['client'][0]['$oid']);

                  if($('#edit-view-record').length){
                    let account = $('#re_income_record_account_id').text();
                    selectize3.setValue(account);
                    selectize3.close();
                  }

                  if (project != 'oficina') {
                    $('.re-income-category-sale').show();
                  }

                  $('.re-income-section-category-sale').hide();
                }
              });
            }
          break;

          default:
            enterprise = $(`.select-re-income-enterprise`)[0].selectize.getValue();
            project = $(`.select-re-income-project`)[0].selectize.getValue();
            $.ajax({
              url: "/records/units",
              method: "POST",
              dataType: "json",
              data: {
                record: {
                  enterprise: enterprise,
                  project: project,
                }
              },
              error: function (xhr, status, error) {
                console.error('AJAX Error: ' + status + error);
              },
              success: function (response) {
                let $select3 = $(document.getElementsByClassName('select-re-income-account')[0]);
                let selectize3 = $select3[0].selectize;

                selectize3.setValue(null);
                selectize3.clearOptions();

                response['accounts'].forEach((element, index) => {
                  selectize3.addOption({
                    value: element[0]['$oid'],
                    text: element[1],
                  });
                });
                selectize3.close();

                if($('#edit-view-record').length){
                  let account = $('#re_income_record_account_id').text();
                  selectize3.setValue(account);
                  selectize3.close();
                }

                $('.re-income-section-category-sale').hide();
                $('.re-income-category-sale').hide();
              }
            });
          break;
        }
      }
    });

    $(".select-re-income-unity")[0].selectize.off()
    $(".select-re-income-unity")[0].selectize.on("change", function (event) {
      let selectCategory = $(`.select-re-income-unity`)[0].selectize;
      let currentValue = selectCategory.getValue();
      
      $.ajax({
        url: "/records/client_by_unit",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            seed_information: currentValue,
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('.client-re-income-unity').val(response['values']);
          $('.client-id-re-income-unity').val(response['client_id']);
        }
      });
    });

    $('#iva-income-activated').change(function () {
      if (this.checked) {
        $('#iva-income').css('display', 'flex');
      } else {
        $('#iva-income').hide();
      }
    });

    $('#reentry-expense-activated').change(function () {
      if (this.checked) {
        $('.expense-section-reentry-area').css('display', 'flex');
        changeTitleForReEntry()
      } else {
        $('.expense-section-reentry-area').hide();
        changeTitleForReEntry()
      }
    });

    function changeTitleForReEntry(){
      arrExpensesTitleContainers = $(".change-title");

      arrExpensesTitleContainers.each(function(i, obj) {
        title = $(obj).text();

        switch (title) {
          case "Empresa":
            $(obj).text("Empresa de salida");
            break;
          case "Empresa de salida":
            $(obj).text("Empresa");
            break;
          case "Proyecto":
            $(obj).text("Proyecto de salida");
            break;
          case "Proyecto de salida":
            $(obj).text("Proyecto");
            break;
          case "Caja o cuenta":
            $(obj).text("Cuenta de salida");
            break;
          case "Cuenta de salida":
            $(obj).text("Caja o cuenta");
            break;
          default:
            break;
        }
      });
    }

    function showExpenseSelectEnterprise() {
      let enterprise = $('.select-expense-enterprise').children("option:selected").val();

      $('.expense-section-enterprise').hide();
      $('.expense-section-project').hide();
      $('.expense-section-category').hide();

      $('.select-expense-project').val('default');

      if (enterprise == 'project') {
        $('#select-expense-category-extra').hide();
        $('#select-expense-category-base').show();
        $('#select-expense-category-base').val('default');
      }
      if (enterprise == 'unit') {
        $('#select-expense-category-base').hide();
        $('#select-expense-category-extra').show();
        $('#select-expense-category-extra').val('default');
      }
      $('.expense-section-enterprise').show();
    };

    $(".select-expense-project")[0].selectize.off()
    $(".select-expense-project")[0].selectize.on("change", function (event) {
      let selected = $('.select-expense-project').children("option:selected").val();

      let selectOptions = $('.select-expense-project').find('.selectize-dropdown-content .option');
      selectOptions.removeClass('selected');

      let currentSelectedOption = $('.select-expense-project').find('.selectize-dropdown-content .option[data-value="' + selected + '"]');
      currentSelectedOption.addClass('selected');

      /* GET CATEGORIES AND SUBCATEGORIES */
      let enterprise = $(`.select-expense-enterprise`)[0].selectize.getValue();
      let project = $(`.select-expense-project`)[0].selectize.getValue();
      let recordId = $('#record_record_id').val();

      $.ajax({
        url: "/records/categories",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            enterprise: enterprise,
            project: project,
            recordId: recordId,
            type: 'expense'
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          let enterprise = $(`.select-expense-enterprise`)[0].selectize.getValue();

          let $select1 = $(document.getElementsByClassName('select-expense-category')[0]);
          let selectize1 = $select1[0].selectize;

          selectize1.setValue(null);
          selectize1.clearOptions();

          if(enterprise == 'inmobiliary_projects') {
            selectize1.addOptionGroup('Costos de venta', {label: 'Costo de venta'});
            selectize1.addOption({
              text: 'Rentas garantizadas',
              value: 'rentas garantizadas',
              optgroup: 'Costos de venta'
            });
            selectize1.addOption({
              text: 'Comisiones sobre venta',
              value: 'comisiones sobre venta',
              optgroup: 'Costos de venta'
            });
          }

          if(enterprise != 'inmobiliary_projects' && response['validate_role'] && selected != 'oficina') {
            selectize1.addOptionGroup('Costo de venta', {label: 'Costo de venta'});
            selectize1.addOption({
              text: 'Abono a Subcontratos *',
              value: 'subcontratos',
              optgroup: 'Costo de venta'
            });
          }

          response['values'].forEach((element, index) => {
            for (const category in element) {
              value = element[category]
              categoryLabel = correctingFinancialCosts(category);
              selectize1.addOptionGroup(category, {label: categoryLabel});
              for (const subcategory in value) {
                selectize1.addOption({
                  text: value[subcategory][0],
                  value: value[subcategory][1]['$oid'],
                  optgroup: category
                });
              }
            }
          });

          selectize1.refreshOptions();
          if($('#edit-view-record').length){
            let category = $('#record_category_id').text();
            selectize1.setValue(category);
            selectize1.close();
          }
        }
      });
      /* GET CATEGORIES AND SUBCATEGORIES */

      $('.expense-section-project').show();
    });

    $(".select-expense-category")[0].selectize.off()
    $(".select-expense-category")[0].selectize.on("change", function (event) {
      let selectizeEnterprise = $(document.getElementsByClassName(`select-expense-enterprise`)[0]);  
      let enterprise = selectizeEnterprise.val();

      let selectCategory = $(`.select-expense-category`)[0].selectize;
      $('.expense-section-category').show();
      let currentValue = selectCategory.getValue();

      if (currentValue != '') {
        let containerCategory = selectCategory.getItem(selectCategory.getValue())[0].parentNode.parentNode;
        let optionSelected = $(containerCategory).find("[data-value='" + currentValue + "']"); 

        let groupSelected = $(optionSelected.parent()[1]).attr("data-group");

        switch (groupSelected) {
          case 'Costos de venta': //AQUÍ SOLO ESTÁ EL "EGRESOS POR VENTA"
            if (enterprise == 'inmobiliary_projects') {
              let enterprise = $(`.select-expense-enterprise`)[0].selectize.getValue();
              let project = $(`.select-expense-project`)[0].selectize.getValue();
              $.ajax({
                url: "/records/units",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select1 = $(document.getElementsByClassName('select-expense-unity')[0]);
                  let $select2 = $(document.getElementsByClassName('select-expense-account')[0]);
                  let selectize1 = $select1[0].selectize;
                  let selectize2 = $select2[0].selectize;

                  selectize1.setValue(null);
                  selectize1.clearOptions();
                  selectize2.setValue(null);
                  selectize2.clearOptions();

                  response['values'].forEach((element, index) => {
                    selectize1.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });

                  response['accounts'].forEach((element, index) => {
                    selectize2.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });

                  selectize1.refreshOptions();
                  //selectize2.refreshOptions();

                  if($('#edit-view-record').length){
                    let unit = $('#record_unit_id').text();
                    selectize1.setValue(unit);
                    selectize1.close();

                    let account = $('#record_account_id').text();
                    selectize2.setValue(account);
                    selectize2.close();
                    $('#check-finished').trigger("change");
                  }
                  
                  selectize2.close();
                  selectize1.close();
                  $('.expense-section-category-sale').show();
                  $('.expense-section-category-supplier').hide();
                }
              });
            }
          break;
          case 'Costo de venta':
            if (currentValue == 'subcontratos') {
              let enterprise = $(`.select-expense-enterprise`)[0].selectize.getValue();
              let project = $(`.select-expense-project`)[0].selectize.getValue();
              $.ajax({
                url: "/records/subcontracts",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select1 = $(document.getElementsByClassName('select-expense-subcontract')[0]);
                  let $select2 = $(document.getElementsByClassName('select-expense-account')[0]);
                  let selectize1 = $select1[0].selectize;
                  let selectize2 = $select2[0].selectize;

                  selectize1.setValue(null);
                  selectize1.clearOptions();
                  selectize2.setValue(null);
                  selectize2.clearOptions();

                  response['values'].forEach((element, index) => {
                    selectize1.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });

                  response['accounts'].forEach((element, index) => {
                    selectize2.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });

                  selectize1.refreshOptions();
                  //selectize2.refreshOptions();

                  if($('#edit-view-record').length){
                    let unit = $('#record_unit_id').text();
                    selectize1.setValue(unit);
                    selectize1.close();
                    
                    let account = $('#record_account_id').text();
                    selectize2.setValue(account);
                    selectize2.close();
                    $('#check-finished').trigger("change");
                  }
                  
                  selectize2.close();
                  selectize1.close();
                  $('.expense-section-category-subcontract').show();
                  $('.expense-section-category-supplier').hide();
                }
              });            
            }else{
              enterprise = $(`.select-expense-enterprise`)[0].selectize.getValue();
              project = $(`.select-expense-project`)[0].selectize.getValue();
  
              $.ajax({
                url: "/records/units",
                method: "POST",
                dataType: "json",
                data: {
                  record: {
                    enterprise: enterprise,
                    project: project,
                  }
                },
                error: function (xhr, status, error) {
                  console.error('AJAX Error: ' + status + error);
                },
                success: function (response) {
                  let $select3 = $(document.getElementsByClassName('select-expense-account')[0]);
                  let selectize3 = $select3[0].selectize;
  
                  selectize3.setValue(null);
                  selectize3.clearOptions();
  
                  response['accounts'].forEach((element, index) => {
                    selectize3.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });
                  selectize3.close();

                  let $select4 = $(document.getElementsByClassName('select-expense-supplier')[0]);
                  let selectize4 = $select4[0].selectize;
  
                  selectize4.setValue(null);
                  selectize4.clearOptions();
  
                  response['suppliers'].forEach((element, index) => {
                    selectize4.addOption({
                      value: element[0]['$oid'],
                      text: element[1],
                    });
                  });
                  selectize4.close();
                  
                  if($('#edit-view-record').length){
                    let account = $('#record_account_id').text();
                    
                    selectize3.setValue(account);
                    selectize3.close();

                    let supplier = $('#record_supplier_id').text();

                    selectize4.setValue(supplier);
                    selectize4.close();
                    $('#check-finished').trigger("change");
                  };

                  $('.expense-section-category-sale').hide();
                  $('.expense-section-category-subcontract').hide();
                  $('.expense-section-category-supplier').show();
                }
              });
            }
            break;
          default:
            enterprise = $(`.select-expense-enterprise`)[0].selectize.getValue();
            project = $(`.select-expense-project`)[0].selectize.getValue();

            $.ajax({
              url: "/records/units",
              method: "POST",
              dataType: "json",
              data: {
                record: {
                  enterprise: enterprise,
                  project: project,
                }
              },
              error: function (xhr, status, error) {
                console.error('AJAX Error: ' + status + error);
              },
              success: function (response) {
                let $select3 = $(document.getElementsByClassName('select-expense-account')[0]);
                let selectize3 = $select3[0].selectize;

                selectize3.setValue(null);
                selectize3.clearOptions();

                response['accounts'].forEach((element, index) => {
                  selectize3.addOption({
                    value: element[0]['$oid'],
                    text: element[1],
                  });
                });
                selectize3.close();

                let $select4 = $(document.getElementsByClassName('select-expense-supplier')[0]);
                let selectize4 = $select4[0].selectize;

                selectize4.setValue(null);
                selectize4.clearOptions();

                response['suppliers'].forEach((element, index) => {
                  selectize4.addOption({
                    value: element[0]['$oid'],
                    text: element[1],
                  });
                });
                selectize4.close();
                
                if($('#edit-view-record').length){
                  let account = $('#record_account_id').text();
                  
                  selectize3.setValue(account);
                  selectize3.close();

                  let supplier = $('#record_supplier_id').text();

                  selectize4.setValue(supplier);
                  selectize4.close();
                  $('#check-finished').trigger("change");
                };

                $('.expense-section-category-sale').hide();
                $('.expense-section-category-subcontract').hide();
                $('.expense-section-category-supplier').show();
              }
            });
          break;
        }
      }

      $('#submit-record').css('display', 'flex');
    });

    $(".select-expense-unity")[0].selectize.off()
    $(".select-expense-unity")[0].selectize.on("change", function (event) {
      let selectCategory = $(`.select-expense-unity`)[0].selectize;
      let currentValue = selectCategory.getValue();
      
      $.ajax({
        url: "/records/client_by_unit",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            seed_information: currentValue,
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('.client-expense-unity').val(response['values']);
          $('.client-id-expense-unity').val(response['client_id']);
        }
      });
    });

    $(".select-expense-subcontract")[0].selectize.off()
    $(".select-expense-subcontract")[0].selectize.on("change", function (event) {
      let selectCategory = $(`.select-expense-subcontract`)[0].selectize;
      let currentValue = selectCategory.getValue();
      let project = $(`.select-expense-project`)[0].selectize.getValue();
      
      $.ajax({
        url: "/records/jobs_by_subcontract",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            subcontract: currentValue,
            project: project
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          let $select1 = $(document.getElementsByClassName('select-expense-job')[0]);
          let selectize1 = $select1[0].selectize;

          selectize1.setValue(null);
          selectize1.clearOptions();

          response['values'].forEach((element, index) => {
            selectize1.addOption({
              value: element[0]['$oid'],
              text: element[1],
            });
          });

          if($('#edit-view-record').length){
            let unit = $('#record_job_id').text();
            selectize1.setValue(unit);
            selectize1.close();
            $('#check-finished').trigger("change");
          }
        }
      });
    });

    $(".select-expense-job")[0].selectize.off()
    $(".select-expense-job")[0].selectize.on("change", function (event) {
      let selectCategory = $(`.select-expense-job`)[0].selectize;
      let currentValue = selectCategory.getValue();
      
      $.ajax({
        url: "/records/jobs_balance",
        method: "POST",
        dataType: "json",
        data: {
          record: {
            job: currentValue,
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('.subcategory-expense-job').val(response['subcategory']);
          $('.balance-expense-job').val(response['balance'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        }
      });
    });

    $('#iva-expense-activated').change(function () {
      if (this.checked) {
        $('#iva-expense').css('display', 'flex');
      } else {
        $('#iva-expense').hide();
      }
    });

    function correctingFinancialCosts(category){
      if (category == "Costos financieros") {
        return "Costo integral financiero";
      }

      return category;
    }
  }

  if ($('.select-archost-enterprise').length) {
    //$(".select-archost-enterprise")[0].selectize.off()
    $(".select-archost-enterprise")[0].selectize.on("change", function(){
      let value = $('.select-archost-enterprise')[0].selectize.getValue();
      $('.select-re-income-enterprise')[0].selectize.setValue(value);
    });
  }

  if ($('.select-archost-enterprise-edit').length) {
    cont_enterprise = 0;
    $(".select-archost-enterprise-edit")[0].selectize.on("change", function(){
      if (cont_enterprise != 0) {
        let value = $('.select-archost-enterprise-edit')[0].selectize.getValue();
        $('.select-re-income-enterprise')[0].selectize.setValue(value);
      }else{
        cont_enterprise = cont_enterprise + 1;
      }
      
    });
  }

  if ($('#enterprise-id-registry').length) {
    //$(".archost-record-type")[0].selectize.off()
    $(".archost-record-type")[0].selectize.on("change", function(){
      let value = $('#enterprise-id-registry').text();
      $('.select-income-enterprise')[0].selectize.setValue(value);
      $('.select-expense-enterprise')[0].selectize.setValue(value);
    });
  }

  $('#addRecordFile').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    let recordId = $('#record_id').val();
    showLoading();
    formData.append('id',recordId)
    $.each($("#record_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#record_upload_files").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/records/add_files',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data['error'] == null) {
          Swal.fire(
            '¡Correcto!',
            'Los archivos se han añadido con éxito',
            'success'
          )
          let array_id_files = data['id_files']
          let files_information = data['files_information']
          $("#download-files").empty();
          array_id_files.forEach((element, index) => {
            $("#download-files").append('<li class="border-gray-200 flex flex-row mb-2">' +
              '<div class="select-none bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
                '<div class="delete-file flex flex-col rounded-md w-10 h-10 cursor-pointer justify-center items-center mr-4" onclick="deleteFile(this);">❌' +
                '<input class="record-id hidden" type="text" value="'+ recordId +'">' +
                '<input class="file-id hidden" type="text" value="'+ element +'/'+ files_information[element] +'">' +
                '</div>' +
                '<div class="flex-1 pl-1 mr-16">' +
                  '<div class="font-medium">'+ files_information[element] +'</div>' +
                '</div>' +
                '<a href="https://devso.s3.amazonaws.com/uploads/consur/files/records/'+ recordId +'/'+ element +'/'+ files_information[element] +'" download><svg class="w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg></a>' +
              '</div>' +
            '</li>');
          });

          $('#records-table').find('.selected-file-modal').find('.icon-file').addClass('text-blue-600');
        } else {
          if (data['error'] == "error_file_max_size") {
            Swal.fire(
              'Verifique el tamaño del archivo',
              'Recuerde que solo puede subir hasta 10MB.',
              'warning'
            )
          }
          if (data['error'] == "error_no_files") {
            Swal.fire(
              'Seleccione uno o más archivos',
              'No se seleccionaron los archivos que desea subir',
              'warning'
            )
          }
          if (data['error'] == "error_file_max") {
            Swal.fire(
              'El número de archivos alcanzó el límite',
              'La cantidad de archivos por registro es de 1.',
              'warning'
            )
          }
        }
        $("#download-pre-load").empty();
        $("#download-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
      }
    });
  });

  $('#record_files').change(function () {
    $("#pre-load").empty();
    $.each($("#record_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 5242880) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 5MB.',
            'warning'
          )
          $("#record_files").val('')
          return
        }
        $("#pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#record_files").val() == '') {
      $("#pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  $('#record_upload_files').change(function () {
    $("#download-pre-load").empty();
    $.each($("#record_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (i < 1) {
          if (file.size > 5242880) {
            Swal.fire(
              'Verifique el tamaño de uno de los archivos',
              'Recuerde que solo puede subir hasta 5MB.',
              'warning'
            )
            $("#record_upload_files").val('')
            return
          }
          $("#download-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
          '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
            '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
            '<div class="flex-1 pl-1 mr-16">' +
              '<div class="font-medium">'+ file.name +'</div>' +
            '</div>' +
            '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
          '</div>' +
        '</li>');
        } else {
          Swal.fire(
            'Verifique el número de archivos',
            'Recuerde que solo puede subir un archivo por registro.',
            'warning'
          )
        }
      });
    });
    if ($("#record_upload_files").val() == '') {
      $("#download-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  function showLoading() {
    let timerInterval
    Swal.fire({
      title: 'Subiendo los archivos',
      html: 'Espere un momento, los archivos se están subiendo al servidor',
      didOpen: () => {
        Swal.showLoading()
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    })
  };

});
