$(document).on('turbolinks:load', () => {
  $('#new-bu-project').click((event) => {
    event.preventDefault();
    $('#modal-bu-gproject').toggle();
  });

  $('#new-ar-project').click((event) => {
    event.preventDefault();
    $('#modal-ar-gproject').toggle();
  });

  $('.close-modal').click(() => {
    $('.modal').hide();
  });

  $(".type-amount").on("change", function(){
    replyTypeAmount = $(this).val();
    containerCostSale = $(this).parent().parent();
    inputAmountCostSale = containerCostSale.find(".input-amount-cost-sale");
    inputPercentage = containerCostSale.find(".input-percentage");

    if(replyTypeAmount == 0){
      inputAmountCostSale.attr("disabled", true);
      inputPercentage.removeAttr("disabled");
    }
    if(replyTypeAmount == 1){
      inputAmountCostSale.removeAttr("disabled");
      inputPercentage.attr("disabled", true);
    }
  })

  $(".input-budget").on("change", function(){
    incomesForSale = $(this).val();

    if (incomesForSale != ''){
      percentage_of_cost_of_sale_amount($(this))
    } else {
      containersDetailsBusinessProject = $(this).parent().parent().siblings();
      containersDetailsBusinessProject.find(".input-amount-cost-sale").val('');
      containersDetailsBusinessProject.find(".input-percentage").val('');
    }
  })

  $(".input-amount-cost-sale").on("change", function(){
    amount_of_cost_of_sale_percentage($(this));
  })

  $(".input-percentage").on("change", function(){
    percentage_of_cost_of_sale_amount($(this));
  })

  function amount_of_cost_of_sale_percentage(item){
    containersDetailsBusinessProject = item.parent().parent().parent().children();
    inputPercentage = containersDetailsBusinessProject.find(".input-percentage");
    inputBudget = containersDetailsBusinessProject.find(".input-budget");
    strIncomesForSale = inputBudget.val();
    strAmount = item.val();

    if ( strAmount != '' ) {
      inputGrossProfit = containersDetailsBusinessProject.find(".input-gross-profit");
      incomesForSale = parseFloat(strIncomesForSale.split('$').join('').split(',').join(''));
      amount = parseFloat(strAmount.split('$').join('').split(',').join(''));
      cvPercentage = (amount * 100) / incomesForSale;
      cvPercentage = cvPercentage.toFixed(3);
      inputPercentage.val(cvPercentage + '%');
      getGrossProfit(inputGrossProfit, inputBudget, item);
    } else {
      inputPercentage.val('');
    }
  }

  function percentage_of_cost_of_sale_amount(item){
    containersDetailsBusinessProject = item.parent().parent().parent().children();
    inputBudget = containersDetailsBusinessProject.find(".input-budget");
    inputPercentage = containersDetailsBusinessProject.find(".input-percentage");
    inputAmountCostSale = containersDetailsBusinessProject.find(".input-amount-cost-sale");
    strIncomesForSale = inputBudget.val();
    percentage = inputPercentage.val();

    if ( percentage != '' ) {
      inputGrossProfit = containersDetailsBusinessProject.find(".input-gross-profit");
      percentage = parseFloat(percentage.split('%'));
      incomesForSale = parseFloat(strIncomesForSale.split('$').join('').split(',').join(''));
      amount = (percentage / 100) * incomesForSale;
      amount = parseFloat(amount).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
      inputAmountCostSale.val(amount);
      inputPercentage.val(percentage + '%');
      getGrossProfit(inputGrossProfit, inputBudget, inputAmountCostSale);
    } else {
      inputAmountCostSale.val('');
      inputPercentage.val('');
    }
  }

  function getGrossProfit(inputGrossProfit, inputBudget, inputAmountCostSale){
    strIncomesForSale = inputBudget.val();
    strAmount = inputAmountCostSale.val();

    if ( strAmount != '' ) {
      incomesForSale = parseFloat(strIncomesForSale.split('$').join('').split(',').join(''));
      amount = parseFloat(strAmount.split('$').join('').split(',').join(''));
      grossProfitValue = incomesForSale - amount;
      grossProfitValue = parseFloat(grossProfitValue).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
      inputGrossProfit.val(grossProfitValue);
    } else {
      inputGrossProfit.val('');
    }
  }
  
});