$(document).on('turbolinks:load', () => {
  if ($('#myChart').length) {

    var ctx = document.getElementById('myChart').getContext('2d');

    var options = {
      title: {
        display: true,
        text: 'Gráfica de Ventas por Tipo de Pago'
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      }
    };

    var barChartData = {
      labels: [
        'Mes 1 / Feb 2021',
        'Mes 2 / Mar 2021',
        'Mes 3 / Abr 2021',
        'Mes 4 / May 2021',
        'Mes 5 / Jun 2021',
        'Mes 6 / Jul 2021',
        'Mes 7 / Ago 2021',
        'Mes 8 / Sep 2021',
        'Mes 9 / Oct 2021',
        'Mes 10 / Nov 2021',
        'Mes 11 / Dic 2021',
        'Mes 12 / Ene 2021'],
      datasets: [{
        label: 'Anticipo',
        backgroundColor: '#5BC0BE',
        data: [
          13222,
          32212,
          12212,
          12812,
          13212,
          3252,
          17212,
          12812,
          1612,
          32212,
          3281,
          5821
        ]
      }, {
        label: 'Mensualidad',
        backgroundColor: '#759FBC',
        data: [
          13222,
          32212,
          12212,
          12812,
          13212,
          3252,
          17212,
          12812,
          1612,
          32212,
          3281,
          5821
        ]
      }, {
        label: 'Saldo',
        backgroundColor: '#384E77',
        data: [
          2212,
          32212,
          12212,
          12812,
          13212,
          3252,
          17212,
          12812,
          1612,
          4212,
          12581,
          42821
        ]
      }, {
        label: 'Tipo de pago 1',
        backgroundColor: '#18314F',
        data: [
          2212,
          3212,
          12212,
          12812,
          13212,
          33252,
          17212,
          12812,
          31612,
          32212,
          12281,
          2821
        ]
      }, {
        label: 'Tipo de pago 2',
        backgroundColor: '#0D0630',
        data: [
          32142,
          2212,
          12212,
          12812,
          13212,
          3252,
          17212,
          12812,
          1612,
          3212,
          9281,
          1521
        ]
      }]
    };

    var myChart = new Chart(ctx, { type: 'bar', options: options, data: barChartData });
  }
})

