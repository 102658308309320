const { when } = require('jquery');

$(document).on('turbolinks:load', () => {
  let building_levels = {};
  let phases = {};

  $('#form-step1').submit(function(e) {
    e.preventDefault();
    if($('.buildings-levels').is(":checked")) {
      $('.name-building').each(function() {
        buildingName = $(this).text();
        building_levels[buildingName] = [];
        $(this).parent().parent().find('.name-level').each(function() {
          levelName = $(this).text();
          building_levels[buildingName].push(levelName);
        })
      })
      $('.building_levels').val(JSON.stringify(building_levels));
    }else{
      building_levels = {};
      $('.building_levels').val(JSON.stringify(building_levels));
    }
  });

  $('#form-step2').submit(function(e) {
    e.preventDefault();
    let phases = {};
    $('.phases-container').each(function() {
      $(this).find('.container-phase-info').each(function() {
        phaseName = $(this).find('.name-phase').text();
        phaseInitialMonth = $(this).find('.initial-month-phase').text();
        phaseFinalMonth = $(this).find('.final-month-phase').text();
        phases[phaseName] = {
          'initial_month': phaseInitialMonth,
          'final_month': phaseFinalMonth
        }
      })
    });

    $('.income-planning-phases').val(JSON.stringify(phases));
  });

  $('.add-building').click(function(e) {
    e.preventDefault();
    let newBuilding =  $('.add-building-input').val();
    
    if(newBuilding == ''){
      return;
    }

    $('.add-building-input').val('');
    $('.buildings-container').append('' +
    '<div class="building-container w-min-content px-4 py-3 mr-4 bg-white border rounded-md mt-2 bg-gray-200">' +
      '<div class="flex justify-between">' +
        '<span class="text-sm name-building">'+ newBuilding +'</span>' +
        '<svg class="delete-building cursor-pointer w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">' +
          '<path fill-rule="evenodd"' +
            'd="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"'+
            'clip-rule="evenodd" />'+
        '</svg>'+
      '</div>'+
      '<ul class="mt-2 levels-container">'+
      '</ul>'+
      '<div class="flex">'+
        '<input type="text" onkeyup="addLevelEnter(event, this)" class="p-2 rounded-md focus:outline-none border-0.5 border mt-2'+
        'flex-1 block w-11/12 rounded-none rounded-r-md sm:text-sm border-gray-900"'+
          'placeholder="Nuevo piso">'+
        '<svg onclick="dragBuildings(this)" class="add-level cursor-pointer w-6 pt-2 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"'+
          'stroke="currentColor">'+
          '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"'+
            'd="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />'+
        '</svg>'+
      '</div>'+
    '</div>'+
    '');

    $('.add-level').click(function(e) {
      e.preventDefault();
      let container = $(this).parent();
      let buildingContainer = container.parent();
      let parentBuilding = buildingContainer.find('.name-building').text()
      let newLevel = container.find('input').val();

      if(newLevel == ''){
        return;
      }

      container.find('input').val('');

      buildingContainer.find('.levels-container').append('' +
        '<li class="mb-2 border-2 border-gray-900 rounded-md pl-3 pr-4 py-2 flex items-center justify-between text-sm">'+
          '<div class="w-0 flex-1 flex items-center">'+
            '<svg class="cursor-pointer flex-shrink-0 h-5 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg"'+
              'viewBox="0 0 20 20" fill="currentColor">'+
              '<path fill-rule="evenodd"'+
                'd="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"'+
                'clip-rule="evenodd" />'+
            '</svg>'+
            '<span class="ml-2 flex-1 w-0 truncate name-level">'+
              newLevel +
            '</span>'+
          '</div>'+
          '<div class="ml-4 flex flex-shrink-0">'+
            '<svg class="delete-level cursor-pointer w-5 ml-2 flex font-medium text-red-600 duration-500 hover:text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">'+
              '<path fill-rule="evenodd"'+
                'd="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"'+
                'clip-rule="evenodd" />'+
            '</svg>'+
          '</div>'+
        '</li>'+
      '');

      $('.delete-level').click(function() {
        $(this).parent().parent().remove();
      }); 
    });

    $('.delete-building').click(function() {
      $(this).parent().parent().remove();
    }); 
  });

  $('.month-validation').change(function(e) {
    let initial_month = $(e.target).val();
    if (initial_month < 0){
      $(e.target).val(1);
    };
  });

  $('.show_month').keyup(function(e) {
    e.preventDefault();
    let sale_date = new Date(document.getElementById('sale_date_sale_showed_personalized').value + 'T00:00:00.00');
    if ($(e.target).val() == 0) {
      document.getElementById('initial-month').innerHTML = ''
      return
    }
    let pay_month = $(e.target).val() - 1;
    let input_type = $(e.target).attr('id');
    sale_date.setMonth(sale_date.getMonth() + pay_month);
    let month = '';
    switch (sale_date.getMonth()) {
      case 0:
        month = 'Enero'
        break;
      case 1:
        month = 'Febrero'
        break;
      case 2:
        month = 'Marzo'
        break;
      case 3:
        month = 'Abril'
        break;
      case 4:
        month = 'Mayo'
        break;
      case 5:
        month = 'Junio'
        break;
      case 6:
        month = 'Julio'
        break;
      case 7:
        month = 'Agosto'
        break;
      case 8:
        month = 'Septiembre'
        break;
      case 9:
        month = 'Octubre'
        break;
      case 10:
        month = 'Noviembre'
        break;
      case 11:
        month = 'Diciembre'
        break;
      default:
        break;
    }

    if(input_type == 'sale_payment_unique_initial_month'){
      document.getElementById('initial-month').innerHTML = 'Fecha pago: ' + month + ' de ' + sale_date.getFullYear()
    }

    if(input_type == 'sale_payment_recurrent_initial_month'){
      document.getElementById('initial-month').innerHTML = 'Fecha pago inicial: ' + month + ' de ' + sale_date.getFullYear()
    }
    
  });

  $('.add-phase').click(function(e) {
    e.preventDefault();
    let namePhase =  $('.add-phase-name').val();
    let initialMonthPhase =  $('.add-phase-initial-month').val();
    let finalMonthPhase =  $('.add-phase-final-month').val();
    
    if(namePhase == '' || initialMonthPhase == '' || finalMonthPhase == ''){
      return;
    }
    $('.add-phase-name').val('');
    $('.add-phase-initial-month').val('');
    $('.add-phase-final-month').val('');

    $('.phases-container').append(''+
      '<div class="flex justify-between flex-wrap p-1 w-1/2 border-2 rounded-sm border-gray-900 shadow-md bg-white mt-1">'+
      '<div class="flex container-phase-info">'+
        '<h2 class="font-bold">Etapa:</h2><span class="pl-1 name-phase">'+ namePhase +'</span>'+
        '<span class="font-bold pl-1">/</span><span class="font-bold pl-1 text-green-700">Mes inicial: </span><span class="pl-1 initial-month-phase">'+ initialMonthPhase +'</span>'+
        '<span class="font-bold pl-1">/</span><span class="font-bold pl-1 text-red-700">Mes final: </span><span class="pl-1 final-month-phase">'+ finalMonthPhase +'</span>'+
      '</div>' +
      '<div>'+
        '<svg class="delete-phase text-red-500 cursor-pointer w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">' +
          '<path fill-rule="evenodd"' +
            'd="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"'+
            'clip-rule="evenodd" />'+
        '</svg>'+
      '</div>'+
    '</div>');

    $('.delete-phase').click(function() {
      $(this).parent().parent().remove();
    }); 
  });

  $('.buildings-levels').on('change', function() {
    $('.has-buildings-levels').toggle();
  });

  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].forEach((step) => {
    selectOptionLayout(step);
  });
  
  function selectOptionLayout(step) {
    if($(`.step${step}-view`).length) {
      $(`.step${step}`).css('background', 'rgba(220,20,60,.9)');
      $(`.step${step}`).css('color', 'white');
    }
    if($(`.expense-flow-view`).length) {
      $(`.step-expense-flow`).css('background', 'rgba(220,20,60,.9)');
      $(`.step-expense-flow`).css('color', 'white');
    }
  };

  $('.show-options-income-planning ').click((e) => {
    e.preventDefault();
    if($(e.target).closest('.show-income-plannings').find('.options-income-planning').is(":visible")) {
      $(e.target).closest('.show-income-plannings').find('.options-income-planning').hide('fast');
    } else{
      $('.options-income-planning').hide('fast');
      $(e.target).closest('.show-income-plannings').find('.options-income-planning').slideToggle('fast');
    }
  });

  $('.close-modal-prices').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('.modal-add-prices');
  });

  /* MODAL EVENTOS CARACTERÍSTICAS */
  $('.show-events-income-planning').click((e) => {
    e.preventDefault();
    let addEvents = $('#modal-add-events');
    addEvents.css("visibility", "visible").animate({opacity: 1});
  });

  $('.show-guaranteed-incomes').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-add-events');
    let guaranteedIncomes = $('#modal-guaranteed-incomes');
    guaranteedIncomes.css("visibility", "visible").animate({opacity: 1});
  });

  $('.save-modal-events').click((e) => {
    e.preventDefault();

    /* GUARDAR DATOS */

    hideVisibilityAnimation('#modal-add-events');
    hideVisibilityAnimation('#modal-guaranteed-incomes');
  });

  $('.close-modal-financing-fields').click((e) => {
    e.preventDefault();
    $('.modal-add-events').addClass('invisible');
    $('.modal-add-events').addClass('opacity-0');
  });

  /* MODAL VER A DETALLE CARACTERÍSTICAS FINANCIAMIENTOS */
  $('.see-characteristics-models').click((e) => {
    e.preventDefault();

    let addAreas = $('#modal-characteristics-models');
    addAreas.css("visibility", "visible").animate({opacity: 1});
    
    /* MOSTRAR DATOS */
    
  });

  $('.close-modal-characteristics-details').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-characteristics-models')
  });

  /* MODAL ASIGNAR FINANCIAMIENTOS */
  $('.see-characteristics-financings').click((e) => {
    
  });

  $('.close-modal-characteristics-financings').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('.modal-characteristics-financings')
  });

  const hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);
  
    modal.css("visibility", "hidden");
    modal.css("opacity", "0")
  };

  /* BORRAR ASIGNADO */

  $('.delete-status').on(('click touchstart'),(e) => {
    e.target.closest('button').remove();
  });

  /* OPEN EVENTS */
  $('.select-event').change(() => {
  });

  $('.select-event-guaranteed').change(() => {
  });

  $('.option-select-guaranteed').change(() => {
    let selected = $('.option-select-guaranteed').children("option:selected").val();
    switch (selected) {
      case '1':
        $('.label-selected-guaranteed').text('Porcentaje del pago');
        $('.money-selected-guaranteed').hide();
        $('.percentage-selected-guaranteed').show();
        break;
      case '2':
        $('.label-selected-guaranteed').text('Monto del pago');
        $('.percentage-selected-guaranteed').hide();
        $('.money-selected-guaranteed').show();
        break;
    
      default:
        break;
    }
  });
  
  function sort_li(a, b){
    return ($(b).data('position')) < ($(a).data('position')) ? 1 : -1;    
  }

  const dragFinancings = () => {
    window.Sortable = require('sortablejs').Sortable;

    let listFinancings = document.getElementById('list-financings');
    let container_financings = document.getElementsByClassName('container-financing');
    for (let item of container_financings) {
      //FASES
      new Sortable(item, {
        group: {
          name:'shared',
          put:function(to,from,item){
          if($(to.el).find("#"+$(item).attr('id')).length>0) return false;
          else return true;
          },
        },
        animation: 150,
        sort: false,
        onSort: function (/**Event*/evt) {
          let array_ordered = $(evt.to).find('button').sort(sort_li);

          $(evt.to).empty();
          $(evt.to).append(array_ordered);
        },
      });
    };
    //FINANCIAMIENTOS
    new Sortable(listFinancings, {
      group: {
          name: 'shared',
          pull: 'clone',
          put: false,
          
      },
      animation: 150,
      sort: false,
    });

    
  };


  if ($('#list-financings').length) {
    dragFinancings();
  };

  if($('.has-buildings-levels').length) {
    window.Sortable = require('sortablejs').Sortable;
  };

  $('.change-status-seeds').click(function() {
    let seed_id = $(this).parent().find('.seed-id').val();

    $('#seed_seed_id_update').val(seed_id);
    $('#modal-status-seed').toggle();
  });

});