$(document).on('turbolinks:load', () => {
  $('#new-project').click((event) => {
    event.preventDefault();
    $('#modal-gproject').toggle();
    $('#modal-gproject').find('#project_name-selectized').click();
  });

  $('.close-modal').click(() => {
    $('.modal').hide();
  });

  $('#new-unit').click((event) => {
    event.preventDefault();
    $('#modal-new-unit').toggle();
  });

  ['general','incomes','expenses', 'registers', 'sales', 'results', 'flow', 'statements' ,'cash', 'reports'].forEach((view) => {
    selectOptionLayoutPrincipal(view);
  });

  function selectOptionLayoutPrincipal(view) {
    if($(`.${view}-view`).length) {
      $(`.${view}`).css('background', 'rgba(220,20,60,.9)');
      $(`.${view}`).css('color', 'white');
    }
  };

  $('.show-planning-menu').click(() => {
    $('.planning-menu').toggle();
  });
});
