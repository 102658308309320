const { when } = require('jquery');

$(document).on('turbolinks:load', () => {

  if( $('.save-absorption-financings').length) {
    $('.save-absorption-financings').click(function() {
      let absorption_id = $(this).parent().parent().find('.absorption_id').val();
      let income_planning_id = $('.income_planning_id_class').val();
      let data = {};

      $(this).parent().parent().find('tbody').find('tr').each(function() {
        data[$(this).find('.name-financing-absorption').val()] = $(this).find('.quantity-financing-absorption').val();
      })

      $.ajax({
        url: "/absorption_financings",
        method: "POST",
        dataType: "json",
        data: {
          absorption_financing: {
            income_planning_id: income_planning_id,
            absorption_id: absorption_id,
            data: data
          }
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          if(response['success'] == true) {
            location.reload(); 
          }
        }
      });
      
    })
  }

  if($('.quantity-per-month').length) {
    verifyToSave();

    $('.quantity-per-month').keyup(function() {
      let row = $(this).parent().parent().parent();
      let table = row.parent();
      let sum_per_stage = table.parent().parent().find('.sum-per-stage').val();

      let quantity = $(this);
      let percentage = row.find('.percentage-per-month');

      percentage.val((quantity.val() / sum_per_stage * 100).toFixed(2));

      verifyToSave();
    });
    
    $('.percentage-per-month').keyup(function() {
      let row = $(this).parent().parent().parent();
      let table = row.parent();
      let sum_per_stage = table.parent().parent().find('.sum-per-stage').val();

      let percentage = $(this);
      let quantity = row.find('.quantity-per-month');

      quantity.val(Math.round(percentage.val() / 100 * sum_per_stage));
      verifyToSave();
    });

    function verifyToSave() {
      let marketing_stages_correct = [];
      $('tbody').each(function() {
        let sumPerStage = $(this).parent().parent().parent().find('.sum-per-stage').val();
        let sumQuantity = 0;
        $(this).find('.quantity-per-month').each(function() {
          if($(this).val() != '') {
            sumQuantity += parseInt($(this).val());
          };
        });

        $(this).closest('.container-marketing-stage').find('.sum-models').text(sumQuantity)

        if(sumQuantity == sumPerStage) {
          marketing_stages_correct.push(true)
        }else{
          marketing_stages_correct.push(false)
        }
      });

      if(marketing_stages_correct.includes(false)){
        $('.save-step-11').hide();
      }else{
        $('.save-step-11').show();
      }
    };
  }

});