var $ = require('jquery');
var dt = require('datatables.net-dt');
const { forEach } = require('jszip');
window.JSZip = require('jszip');
require('pdfmake');
require('datatables.net-buttons-dt');
// require( 'datatables.net-fixedcolumns');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/dataTables.buttons.min')();
require('datatables.net-buttons/js/buttons.flash.min')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-rowgroup-dt');

$(document).on('turbolinks:load', () => {
  var img64 = window.enterpriseLogo;
  var numFile = 1;
  var isOtherPage = true;
  var titleTable = "";
  var subtitle = "";
  var titleRangeMonths = "";
  var currentdate = new Date();
  var columnsVisible = range(0,11);
  var datetime = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/"
              + currentdate.getFullYear();
  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          if(column > 0) {
            node = $(node).find(".data-value");
            return node.is("input") ? node.val() : node.text().trim();
          }else{
            return row + 1;
          }
        }
      }
    }
  };

  if ( $("#ip-planned-cash-flow").length ) { 
    projectName = $("#project-name").val();
    titleTable = "Flujo de efectivo planeado";
    subtitle = `Proyecto inmobiliario: ${projectName}`
    rangeMonths = $("#range-months").val();
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
  }

  if ( $("#annual-budget-detail").length ) { 
    businessUnit = $("#business-unit-name").val();
    rangeMonths = $("#range-months").val();
    titleTable = "Planeación de detalle anual";
    subtitle = `Unidad de negocio: ${businessUnit}`
    titleRangeMonths = `Rango de Fecha: ${rangeMonths}`;
  }

  /*$('*[class*="expand-"]').click((element) => {
    expandRow(element.currentTarget);
  });*/

  $(document.body).on('click', '*[class*="expand-"]' ,function(element){
    expandRow(element.currentTarget);
  });

  const expandRow = (element) => {
    let rotateDeg = $(element).find('svg').css('transform');

    if(rotateDeg == 'none' || rotateDeg == 'matrix(1, 0, 0, 1, 0, 0)') {
      $(element).find('svg').css('transform', 'rotate(-90deg)');
      toggleLevel(element, 'hide');
    }else{
      $(element).find('svg').css('transform', 'rotate(0deg)');
      toggleLevel(element, 'show');
    }
  }

  const toggleLevel = (element, toggleType) => {
    let classClicked = $(element).attr('class').split(' ')[0];
    if(classClicked.match(/-/g).length == 1) {
      let numbers = classClicked.split('-');
      let firstLevel = numbers[1];
      toggleLevel1(toggleType, firstLevel);
    }
    if(classClicked.match(/-/g).length == 2) {
      let numbers = classClicked.split('-');
      let firstLevel = numbers[1];
      let secondLevel = numbers[2]; 
      toggleLevel2(toggleType, firstLevel, secondLevel);
    }
    if(classClicked.match(/-/g).length == 3) {
      let numbers = classClicked.split('-');
      let firstLevel = numbers[1];
      let secondLevel = numbers[2]; 
      let thirdLevel = numbers[3]; 
      toggleLevel3(toggleType, firstLevel, secondLevel, thirdLevel);
    }
  };

  const toggleLevel1 = (toggleType, selectedLevel) => {
    let actualLevel = 0;
    let actualElement;
    do {
      actualLevel++;
      actualElement = $(`.expand-${selectedLevel}-${actualLevel}`);
      if(toggleType == 'show') {
        $(actualElement).find('svg').css('transform', 'rotate(0deg)');
        actualElement.show();
      }
      if(toggleType == 'hide') {
        actualElement.hide();
      }
      toggleLevel2(toggleType, selectedLevel, actualLevel);
    }while(actualElement.length);
  };

  const toggleLevel2 = (toggleType, selectedLevel, selectedLevel1) => {
    let actualLevel = 0;
    let actualElement;
    do {
      actualLevel++;
      actualElement = $(`.expand-${selectedLevel}-${selectedLevel1}-${actualLevel}`);
      if(toggleType == 'show') {
        actualElement.show();
        $(actualElement).find('svg').css('transform', 'rotate(0deg)');
      }
      if(toggleType == 'hide') {
        actualElement.hide();
      }
      toggleLevel3(toggleType, selectedLevel, selectedLevel1, actualLevel);
    }while(actualElement.length);
  }

  const toggleLevel3 = (toggleType, selectedLevel, selectedLevel1, selectedLevel2) => {
    let actualLevel = 0;
    let actualElement;
    do {
      actualLevel++;
      actualElement = $(`.expand-${selectedLevel}-${selectedLevel1}-${selectedLevel2}-${actualLevel}`);
      if(toggleType == 'show') {
        actualElement.show();
        $(actualElement).find('svg').css('transform', 'rotate(0deg)');
      }
      if(toggleType == 'hide') {
        actualElement.hide();
      }
    }while(actualElement.length);
  }

  // if ( $("#table-accounts").length ) {
  //   arrExpand = $('*[class*="expand-"]');

  //   $.each(arrExpand, function(i, element) { 
  //     expandRow(element);
  //    });
  // }

  var collapsedGroups = {};
  var table = $('#table-cash-flow').DataTable({
    scrollY:        '68vh',
    scrollX:        true,
    scrollCollapse: true,
    paging:         false,
    fixedColumns:   {
      leftColumns: 3
    },
    responsive: true,
    "language": {
      "lengthMenu": "Mostrar _MENU_ registros por página",
      "zeroRecords": "Nada encontrado",
      "info": "Mostrando página _PAGE_ de _PAGES_",
      "infoEmpty": "No hay registros disponibles",
      "infoFiltered": "(filtrado de _MAX_ registros)",
      "sSearch": "Buscar:",
      "oPaginate": {
        "sFirst": "Primero",
        "sPrevious": "Anterior",
        "sNext": "Siguiente",
        "sLast": "Último"
      }
    },
    "lengthMenu": [[-1], ["All"]],
    dom: 'Blftip',
    buttons: [
      $.extend( true, {}, buttonCommon, {
          extend: 'copyHtml5'
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'excelHtml5',
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
         
            $('row:eq(0) c', sheet).attr( 's', '50' );
            $('row:eq(1) c', sheet).attr( 's', '50' );
            $('row:last c', sheet).attr('s','57');
          },
          title: titleTable,
          messageTop: function() {
            return `${subtitle}.
                    Fecha de exportación: ${datetime}
                    ${titleRangeMonths}`
          },
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'pdfHtml5',
          footer: true,
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: function() {
            return `${titleTable}_${numFile}`;
          },
          messageTop: function() {
            return `${subtitle}.
                    Fecha de exportación: ${datetime}
                    ${titleRangeMonths}`
          },
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisible) < 0) {
                return false;
              }
              return true;
            },
            stripHtml: false,
            stripNewlines: false
          },
          customize: function ( doc ) {
            doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};

            for (let i = 0; i <  doc.content[2].table.body[0].length; i++) {
              doc.content[2].table.body[0][i].text = doc.content[2].table.body[0][i].text.replace("<br>","\n");
            }

            for (let i = 0; i <  doc.content[2].table.body.length; i++) {
              titleRow = doc.content[2].table.body[i][1].text;
              titleRow = titleRow.toLowerCase();
              
              switch (titleRow) {
                case "saldo inicial":
                  styleRowDatatable(doc,i);
                  break;
                case "saldo final":
                  styleRowDatatable(doc,i);
                  break;
                case "total ingresos":
                  styleRowDatatable(doc,i);
                  break;
                case "ingresos por venta":
                  styleRowDatatable(doc,i);
                  break;
                case "costo integral financiero":
                  styleRowDatatable(doc,i);
                  break;
                case "otros ingresos":
                  styleRowDatatable(doc,i);
                  break;
                case "total egresos":
                  styleRowDatatable(doc,i);
                  break;
                case "costos de venta":
                  styleRowDatatable(doc,i);
                  break;  
                case "costos totales":
                  styleRowDatatable(doc,i);
                  break; 
                case "costo de venta":
                  styleRowDatatable(doc,i);
                  break; 
                case "hardcost":
                  styleRowDatatable(doc,i);
                  break;
                case "softcost":
                  styleRowDatatable(doc,i);
                  break; 
                case "costo integral financiero":
                  styleRowDatatable(doc,i);
                  break;
                case "impuestos":
                  styleRowDatatable(doc,i);
                  break;
                case "otros egresos":
                  styleRowDatatable(doc,i);
                  break;
                case "valor del terreno":
                  styleRowDatatable(doc,i);
                  break;
                default:
                  break;
              }
            }

            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
      } )
    ],
    aaSorting: [],
    ordering: false,
    searching: false,
  });

  var  columnsLengthTable = table.columns().count();

  $(".buttons-pdf").on("click", function () {
    if(isOtherPage) {
      numLaps = 1;
      numFile = 2;
      if ( (columnsVisible[columnsVisible.length -1] + 12) < columnsLengthTable) {
        columnsLength = columnsVisible.length + 12;
      }else{
        columnsLength = columnsVisible[columnsVisible.length -1] + (columnsLengthTable - columnsVisible[columnsVisible.length -1])
        isOtherPage = false;
      }
      columnInitial = columnsVisible[columnsVisible.length -1] + 1;
      columnsVisible = []
      for (let i = columnInitial; i < columnsLength; i++) {
        switch (numLaps) {
          case 1:
            columnsVisible.push(0);
            i -= 1;
            numLaps = 2;
            break;
          case 2:
            columnsVisible.push(1);
            columnsVisible.push(2);
            i -= 1;
            numLaps = 3;
            break;
          default:
            columnsVisible.push(i);
            break;
        }
      }
      table.buttons('.buttons-pdf').trigger()
    }else{
      isOtherPage = true;
      columnsVisible = range(0,12);
      numFile = 1;
    }
  });
  
  $('#table-cash-flow tbody').on('click', 'tr.dtrg-group', function () {
    var name = $(this).data('name');
    collapsedGroups[name] = !collapsedGroups[name];
    table.draw(false);
  });

  $('.show-expenses-detailed').click((e) => {
    e.preventDefault();
    showVisibilityAnimation($('#modal-expenses-detailed'));
  });

  $('.close-modal-expenses-detailed').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-expenses-detailed');
  });

  const showVisibilityAnimation = (modalName) => {
    modalName.css('visibility', 'visible').animate({ opacity: 1 });
  }

  const hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);

    modal.css("visibility", "hidden");
    modal.css("opacity", "0")
  };

  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  function styleRowDatatable(doc, indexRow){
    hashStyles = {fontSize: 11, bold: true}

    for (let j = 0; j <  doc.content[2].table.body[indexRow].length; j++) {
      doc.content[2].table.body[indexRow][j].style = hashStyles;
    }
  }
})
