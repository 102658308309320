$(document).on('turbolinks:load', () => {
  let months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
  let type = $('#type-budget');
  let budget = $('#actual-budget');

  let typeBudget = $('#type_budget');
  let yearBudget = $('#year_budget');
  let monthBudget = $('#month_budget');

  let typeBudgetPlanning;
  let yearBudgetPlanning;
  let monthBudgetPlanning;

  typeBudget.val('annual');
  yearBudget.val(budget.text());
  monthBudget.val('');

  if($(".link-to-budget").length){
    changeSumatories();
  }

  function setPlanningParameters(source) {

    if(source=='annual-change-next'){
      let actual_year = parseInt($("#actual-annual-budget").text());
      actual_year++;

      typeBudgetPlanning = 'annual';
      yearBudgetPlanning = actual_year;
      monthBudgetPlanning = '';
    }
    if(source=='annual-change-previous'){
      let actual_year = parseInt($("#actual-annual-budget").text());
      actual_year--;
      
      typeBudgetPlanning = 'annual';
      yearBudgetPlanning = actual_year;
      monthBudgetPlanning = '';
    }
    if(source=='page-load'){
      type_budget = type.val(); 

      if(type_budget == 0) {
        typeBudgetPlanning = 'annual';
        yearBudgetPlanning = parseInt(budget.text());
        monthBudgetPlanning = '';
      }
      if(type_budget == 1) {
        typeBudgetPlanning = 'monthly';
        yearBudgetPlanning = parseInt(budget.text().substr(-4));
        monthBudgetPlanning = budget.text().replace(` ${yearBudgetPlanning}`, '');
      }
    }
  }

  $('.link-to-budget').click(function() {
    setPlanningParameters('page-load');
    $(this).attr("href", function() {
      return `${this.href}?type_budget=${typeBudgetPlanning}&year_budget=${yearBudgetPlanning}&month_budget=${monthBudgetPlanning}`;
    });
  });

  $('.return-to-budgets').click(function() {
    setPlanningParameters('page-load');
    $(this).attr("href", function() {
      return `${this.href}&type_budget=${typeBudgetPlanning}&year_budget=${yearBudgetPlanning}&month_budget=${monthBudgetPlanning}`;
    });
  });

  $('.update-budget-date').click(function() {

    let categoryTitle = $("#planning-category-title").text();
    let categoryURL;

    const categoryURLs = {
      'Ingresos por venta': 'sale_proceeds',
      'Costo de venta': 'cost_sales',
      'Costo integral financiero': 'financial_costs',
      'Gastos de operación': 'operating_expenses',
      'Otros egresos': 'other_expenses',
      'Otros ingresos': 'other_incomes',
      'Impuestos': 'taxes'
    };
    
    if (categoryTitle in categoryURLs) {
      categoryURL = categoryURLs[categoryTitle];
    } else {
      categoryURL = '';
    }

    setPlanningParameters('page-load');

    $(this).attr("href", function() {
      return `${this.href}${categoryURL}?type_budget=${typeBudgetPlanning}&year_budget=${yearBudgetPlanning}&month_budget=${monthBudgetPlanning}`;
    });
  });

  $('.next-annual-budget').click(function() {
    setPlanningParameters('annual-change-next');

    $(this).attr("href", function() {
      return `${this.href}annual_budget_detail?type_budget=${typeBudgetPlanning}&year_budget=${yearBudgetPlanning}&month_budget=${monthBudgetPlanning}`;
    });
  });

  $('.previous-annual-budget').click(function() {
    setPlanningParameters('annual-change-previous');

    $(this).attr("href", function() {
      return `${this.href}annual_budget_detail?type_budget=${typeBudgetPlanning}&year_budget=${yearBudgetPlanning}&month_budget=${monthBudgetPlanning}`;
    });
  });

  $('.link-to-detail-annual').click(function() {
    let typeBudgetPlanning;
    let yearBudgetPlanning;
    let monthBudgetPlanning;

    type_budget = type.val(); 

    if(type_budget == 0) {
      typeBudgetPlanning = 'annual';
      yearBudgetPlanning = parseInt(budget.text());
      monthBudgetPlanning = '';
    }
    if(type_budget == 1) {
      typeBudgetPlanning = 'monthly';
      yearBudgetPlanning = parseInt(budget.text().substr(-4));
      monthBudgetPlanning = budget.text().replace(` ${yearBudgetPlanning}`, '');
    }

    $(this).attr("href", function() {
      return `${this.href}?type_budget=${typeBudgetPlanning}&year_budget=${yearBudgetPlanning}&month_budget=${monthBudgetPlanning}`;
    });
  });

  $('#type-budget').click(() => {
    type_budget = type.val();
    if(type_budget == 0) {
      let actual_year = budget.text().substr(-4);
      budget.text(actual_year);
      setParams('annual', '', actual_year);
    }
    if(type_budget == 1) {
      let actual_year = budget.text().substr(-4);
      budget.text(months[0] + ' ' + budget.text().substr(-4));
      setParams('monthly', months[0], actual_year);
    }

    changeSumatories();
  });

  $('#before-budget').click(() => {
    type_budget = type.val(); 
    if(type_budget == 0) {
      let actual_year = parseInt(budget.text());
      actual_year--;
      budget.text(actual_year);
      setParams('annual', '', actual_year);
    }
    if(type_budget == 1) {
      let actual_year = parseInt(budget.text().substr(-4));
      let actual_month = budget.text().replace(` ${actual_year}`, '');
      let index_month = parseInt(months.indexOf(actual_month));

      if(index_month == 0) {
        actual_year--
        budget.text(months[11] + ' ' + actual_year);
        setParams('monthly', months[11], actual_year);
      }else {
        index_month--
        budget.text(months[index_month] + ' ' + actual_year);
        setParams('monthly', months[index_month], actual_year);
      }
    }

    changeSumatories();
  });

  $('#next-budget').click(() => {
    type_budget = type.val(); 
    if(type_budget == 0) {
      let actual_year = parseInt(budget.text());
      actual_year++;
      budget.text(actual_year);
      setParams('annual', '', actual_year);
    }
    if(type_budget == 1) {
      let actual_year = parseInt(budget.text().substr(-4));
      let actual_month = budget.text().replace(` ${actual_year}`, '');
      let index_month = parseInt(months.indexOf(actual_month));

      if(index_month == 11) {
        actual_year++
        budget.text(months[0] + ' ' + actual_year);
        setParams('monthly', months[0], actual_year);
      }else {
        index_month++;
        budget.text(months[index_month] + ' ' + actual_year);
        setParams('monthly', months[index_month], actual_year);
      }
    }

    changeSumatories();
  });

  function changeSumatories() {
    let actual_budget = $('#actual-budget').text();

    $.ajax({
      url: `budgets`,
      method: "GET",
      dataType: "json",
      data: {
        reload: true,
        year_budget: actual_budget
      },
      error: function (xhr, status, error) {
        console.log('ERROR')
      },
      success: function (response) {
        let values = $('.business-unit-budget');
        let containers = $('.business-unit-subcategories');

        let sale_proceed = 0.0;
        let cost_sale = 0.0;
        let gross_profit = 0.0;
        let operating_expense = 0.0;
        let income_before_taxes = 0.0;
        let financial_cost = 0.0;
        let tax = 0.0;
        let net_income = 0.0;
        let other_income = 0.0;
        let other_expense = 0.0;

        if (Array.isArray(response['sale_proceed_categories_sum'])) {
          sale_proceed = `${response['sale_proceed_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          sale_proceed = `${response['sale_proceed_categories_sum']}`;
        }
        $(values[0]).val(parseFloat(sale_proceed, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[0]).empty();
        response['sale_proceed_categories'].forEach((element, index) => {
          $(containers[0]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="'+ parseFloat(response['sale_proceed_categories_sum'][index], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })  +'" disabled>'+
            '</div>'+
          '');
        });

        if (Array.isArray(response['cost_sales_categories_sum'])) {
          cost_sale = `${response['cost_sales_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          cost_sale = `${response['cost_sales_categories_sum']}`;
        }
        $(values[1]).val(parseFloat(cost_sale, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[2]).empty();
        response['operating_expenses_categories'].forEach((element, index) => {
          $(containers[2]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="-" disabled>'+
            '</div>'+
          '');
        });

        gross_profit = sale_proceed - cost_sale;
        $(values[2]).val(parseFloat(gross_profit, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));

        if (Array.isArray(response['operating_expenses_categories_sum'])) {
          operating_expense = `${response['operating_expenses_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          operating_expense = `${response['operating_expenses_categories_sum']}`;
        }
        operating_expense = parseFloat(operating_expense) * (-1);
        $(values[3]).val(parseFloat(operating_expense, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[2]).empty();
        response['operating_expenses_categories'].forEach((element, index) => {
          $(containers[2]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="'+ parseFloat(response['operating_expenses_categories_sum'][index] * (-1), 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })  +'" disabled>'+
            '</div>'+
          '');
        });

        income_before_taxes = gross_profit - operating_expense;
        $(values[4]).val(parseFloat(income_before_taxes, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));

        if (Array.isArray(response['financial_costs_categories_sum'])) {
          financial_cost = `${response['financial_costs_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          financial_cost = `${response['financial_costs_categories_sum']}`;
        }
        financial_cost = parseFloat(financial_cost) * (-1);
        $(values[5]).val(parseFloat(financial_cost, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[3]).empty();
        response['financial_costs_categories'].forEach((element, index) => {
          $(containers[3]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="'+ parseFloat(response['financial_costs_categories_sum'][index] * (-1), 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })  +'" disabled>'+
            '</div>'+
          '');
        });

        if (Array.isArray(response['taxes_categories_sum'])) {
          tax = `${response['taxes_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          tax = `${response['taxes_categories_sum']}`;
        }
        tax = parseFloat(tax) * (-1);
        $(values[6]).val(parseFloat(tax, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[4]).empty();
        response['taxes_categories'].forEach((element, index) => {
          $(containers[4]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="'+ parseFloat(response['taxes_categories_sum'][index] * (-1), 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })  +'" disabled>'+
            '</div>'+
          '');
        });

        net_income = income_before_taxes - financial_cost - tax;
        $(values[7]).val(parseFloat(net_income, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));

        if (Array.isArray(response['other_incomes_categories_sum'])) {
          other_income = `${response['other_incomes_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          other_income = `${response['other_incomes_categories_sum']}`;
        }
        $(values[8]).val(parseFloat(other_income, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[5]).empty();
        response['other_incomes_categories'].forEach((element, index) => {
          $(containers[5]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="'+ parseFloat(response['other_incomes_categories_sum'][index], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })  +'" disabled>'+
            '</div>'+
          '');
        });

        if (Array.isArray(response['other_expenses_categories_sum'])) {
          other_expense = `${response['other_expenses_categories_sum'].reduce((a, b) => a + b, 0)}`;
        }else{
          other_expense = `${response['other_expensestaxes_categories_sum']}`;
        }
        other_expense = parseFloat(other_expense) * (-1);
        $(values[9]).val(parseFloat(other_expense, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        $(containers[6]).empty();
        response['other_expenses_categories'].forEach((element, index) => {
          $(containers[6]).append('' +
            '<div class="flex rounded-md shadow-sm">'+
              '<span class="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-4000 text-gray-900 text-md font-semibold">'+
                element +
              '</span>'+
              '<input type="text" class="cursor-pointer p-2 focus:outline-none flex-1 w-10 rounded-r-md text-md text-center bg-gray-200  bg-gray-200 border border-gray-300"'+
                'value="'+ parseFloat(response['other_expenses_categories_sum'][index] * (-1), 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })  +'" disabled>'+
            '</div>'+
          '');
        });
      }
    });
  };

  const setParams = (type, month, year) => {
    typeBudget.val(type);
    yearBudget.val(year);
    monthBudget.val(month);
  };
});