var $  = require( 'jquery' );
var dt = require('datatables.net-dt');
window.JSZip = require('jszip');
require('pdfmake');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.colVis.js' )();
require('datatables.net-buttons/js/dataTables.buttons.min')();
require('datatables.net-buttons/js/buttons.flash.min')();
require('datatables.net-buttons/js/buttons.html5.js' )();
var selectize = require('selectize');

$(document).on('turbolinks:load', () => {
  var img64 = window.enterpriseLogo;
  var currentdate = new Date();
  var datetime = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/"
              + currentdate.getFullYear();
  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          if(column > 0) {
             node = $(node).find(".data-value");
             return node.is("input") ? node.val() : node.text().trim();
          }else{
            return row + 1
          }
        }
      }
    }
  };

  if ( $("#income-flow").length ) { 
    seedName = $("#seed-name").val();
    titleTable = "Sembrado editable"
    titlePlanning = `Planeacion de ingresos: Sembrado ${seedName}` 
  }

  if ( $("#seed-original").length ) { 
    seedName = $("#seed-name").val();
    titleTable = "Sembrado original"
    titlePlanning = `Planeacion de ingresos: Sembrado ${seedName}` 
  }

  if($('#seed-table').length) {
    $('#seed-table').DataTable({
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      buttons: [
        $.extend( true, {}, buttonCommon, {
            extend: 'copyHtml5'
        } ),
        $.extend( true, {}, buttonCommon, {
            extend: 'excelHtml5',
            title: function() {
              return `${titleTable}`;
            },
            messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                        ${titlePlanning}.\n
                        Fecha de exportación: ${datetime}\n
                        Rango de Fecha: ${$("#range-months").val()}\n`,
        } ),
        $.extend( true, {}, buttonCommon, {
            extend: 'pdfHtml5',
            orientation: 'landscape',
            pageSize: 'LEGAL',
            title: function() {
              return `${titleTable}`;
            },
            messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.
                        ${titlePlanning}.
                         Fecha de exportación: ${datetime}
                         Rango de Fecha: ${$("#range-months").val()}`,
                         
            customize: function ( doc ) {
              doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};

              if ( img64 != '' ) {
                doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
                } );
              }
            }
        } )
    ],
    "drawCallback":function(){
      var api = this.api()
      total_prices = 0.00;
      total_position = 8;
      value_position = 9;
      
      if ($('#seed-buildings_levels').length) {
        total_position = 10;
        value_position = 11;
      }

      $(api.column(total_position).footer()).html(
        'Total: '
      )

      api.column(value_position, {page:'current'}).data().each(function(val, i) {
        newStr = this[i].substring(this[i].indexOf('value=') + 1);
        newStr = newStr.split('"')[1];
        newStr = newStr.replace(/[^\d.-]/g, '') * 1;
        total_prices += newStr;
      });

      $(api.column(value_position).footer()).html(
        total_prices.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
      );

    } 
    });
  }
  
  /* SELECTIZE */

  const setSelectizeCreate = (element) => {
    element.selectize({
      create: true,
      sortField: {
        field: 'text',
        direction: 'asc'
      },
      dropdownParent: 'body',
    });
    element.closest('.selectize-control').show();
  };
  const setSelectizeNotCreate = (element) => {
    element.selectize({
      sortField: {
        field: 'text',
        direction: 'asc'
      },
      dropdownParent: 'body'
    });
    element.closest('.selectize-control').show();
  };

  $('#seed-table').on('click', '.c-unit-type', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeCreate(select);
  });

  $('#seed-table').on('change', '.c-unit-type select', function() {
    let value = checkEmptySelect(this);
    changeValue(this,'unit_type', value );
  });

  $('#seed-table').on('click', '.c-unit-name', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeCreate(select);
  });

  $('#seed-table').on('change', '.c-unit-name select', function() {
    let value = checkEmptySelect(this);
    changeValue(this,'unit_name', value );
  });

  $('#seed-table').on('click', '.c-model', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeCreate(select);
  });

  $('#seed-table').on('change', '.c-model select', function() {
    let value = checkEmptySelect(this);
    changeValue(this,'unit_model', value );
  });

  $('#seed-table').on('click', '.c-building', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeCreate(select);
  });

  $('#seed-table').on('change', '.c-building select', function() {
    changeValue(this,'building', $(this).val() );
  });

  $('#seed-table').on('click', '.c-level', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeCreate(select);
  });

  $('#seed-table').on('change', '.c-level select', function() {
    changeValue(this,'level', $(this).val() );
  });

  $('#seed-table').on('click', '.c-phase', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeNotCreate(select);
  });

  $('#seed-table').on('change', '.c-phase select', function() {
    changeValue(this,'ip_marketing_stage_id', $(this).val() );
  });

  $('#seed-table').on('click', '.c-financing', function() {
    $(this).find('p').hide();
    select = $(this).find('select');
    setSelectizeNotCreate(select);
  });

  $('#seed-table').on('change', '.c-financing select', function() {
    changeValue(this,'ip_financing_id', $(this).val() );
  });

  function checkEmptySelect(item) {
    let value = '';
    value = $(item).text();

    return value;
  }

  const changeValue = (element, columnName, value) => {
    if (value == '' || value == null) { return }

    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    let seedInformationId = $(element).closest('tr').find('.seed-information-id').val();
    let seed_information = { }

    seed_information[columnName] = value

    $.ajax({
      url: `/seed_informations/${seedInformationId}`,
      method: "PUT",
      dataType: "json",
      data: {
        id: seedInformationId,
        seed_information: seed_information,
        authenticity_token: AUTH_TOKEN,
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        let tr = $(element).parent().parent();
        
        if(response['m2_priced']) {
          let m2_priced = parseFloat(response['m2_priced'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          tr.find('.price_m2').val(m2_priced);
        }
        if(response['total_priced']) {
          let total_priced = parseFloat(response['total_priced'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          tr.find('.price_total_m2').val(total_priced);
        }
        
        if(response['ip_financing']) {
          $(element).closest('tr').find('.c-financing p').text(response['ip_financing'])
        }
        if(response['ip_financings']) {
          if($(element).closest('tr').find('.c-financing .selectize-control').length) {
            $(element).closest('tr').find('.c-financing select').selectize()[0].selectize.destroy();
          }
          $(element).closest('tr').find('.c-financing select').empty();
          $(element).closest('tr').find('.c-financing p').hide();
          $.each(response['ip_financings'], function(key, value) {
            if ($(element).closest('tr').find('.c-financing').find('p').text() == value[1]) {
              if($(element).closest('tr').find('.c-financing .selectize-control').length) {
              }else{
                $(element).closest('tr').find('.c-financing select').hide();
                $(element).closest('tr').find('.c-financing p').show();
              }
            }
            $(element).closest('tr').find('.c-financing select').hide();
            $(element).closest('tr').find('.c-financing select').append(`<option value='${value[0]['$oid']}'>${value[1]}</option>`);
          });
          $(element).closest('tr').find('.c-financing select').append(`<option value='' selected disabled></option>`);
          
          if($(element).closest('tr').find('.c-financing .selectize-control').length) {
            $(element).closest('tr').find('.c-financing select').selectize({
              sortField: {
                field: 'text',
                direction: 'asc'
              },
              dropdownParent: 'body'
            });
          };
        }
      }
    });
  };

  const hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);
  
    modal.css("visibility", "hidden");
    modal.css("opacity", "0")
  };
})

